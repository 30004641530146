<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_shippingScheduleAdd" />
      <v-form ref="form" lazy-validation>
        <!-- 検索項目-->
        <v-container fluid>
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :rules="[rules.inputRequired]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷先-->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="shippingSelected"
                :items="shippingList"
                item-text="displayText"
                item-value="value"
                :label="$t('label.lbl_shippingTo')"
                :hint="setSrhShipping()"
                class="txt-single"
                persistent-hint
                :rules="[rules.inputRequired]"
                @change="changeArrival"
                :search-input.sync="getSuppliersSelected"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷No -->
            <div class="textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="txt_slipNo"
                maxlength="50"
                :label="$t('label.lbl_shippingNo')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷予定日付-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="shippingScheduleDate"
                :label="$t('label.lbl_shippingScheduleDate')"
                @change="changeDate"
                :rules="[rules.yyyymmdd]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>

            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="shippingScheduleDateCal"
                  @input="dateMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 品質区分-->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="shippingStatusSelected"
                :items="shippingStatusList"
                :label="$t('label.lbl_arrivalStatus')"
                class="txt-single"
                persistent-hint
                :rules="[rules.inputRequired]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 責任区分-->
            <v-btn-toggle
              mandatory
              v-model="isRes"
              color="blue darken-2"
              class="toggle black--text"
            >
              <v-btn
                mandatory
                class="res-btn"
                v-for="item in blameDivList"
                :key="item.value"
                :value="item.value"
                small
                v-bind:disabled="isDisabledBlame"
              >
                {{ item.text }}</v-btn
              >
            </v-btn-toggle>
          </v-row>
          <!-- 2段目 -->
          <v-row>
            <div class="search-row-exeBtn first-search-item">
              <!-- トラック-->
              <div class="serch-textbox-tr" style="float: left">
                <v-text-field
                  outlined
                  dense
                  v-model="truckNm"
                  maxlength="50"
                  :label="$t('label.lbl_shippingCompanyName')"
                  class="txt-single"
                  readonly
                  clearable
                  clear-icon="mdi-close-circle"
                  :rules="[rules.inputRequired]"
                />
              </div>

              <span class="require asterisk-spacer" style="float: left">*</span>

              <!-- 輸送会社選択ボタン -->
              <div style="float: left">
                <v-btn color="primary" class="api-btn" v-on:click="openTruck">
                  {{ $t("btn.btn_addShpAdress") }}
                </v-btn>
              </div>

              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <!-- 有償/無償-->
              <div style="float: left">
                <v-btn-toggle
                  mandatory
                  v-model="isPaid"
                  color="blue darken-2"
                  id="btn-toggle-btn-padding"
                >
                  <v-btn
                    class="paid-btn"
                    v-for="item in freeCostDivList"
                    :key="item.value"
                    :value="item.value"
                    small
                  >
                    {{ item.text }}</v-btn
                  >
                </v-btn-toggle>
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <!-- 無償理由選択プルダウン-->
              <div class="search-autocomplete" style="float: left">
                <v-autocomplete
                  dense
                  class="list-single"
                  v-model="freeReasonSelected"
                  :items="freeReasonList"
                  v-bind:disabled="isDisabledFreeReason"
                  :label="$t('label.lbl_freeReason')"
                >
                  <template slot="item" slot-scope="data">
                    <span class="auto-txt-size"> {{ data.item.text }} </span>
                  </template>
                </v-autocomplete>
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
              <!-- 処理区分-->
              <div class="search-autocomplete" style="float: left">
                <v-autocomplete
                  dense
                  v-model="processDivSelected"
                  :items="processDivList"
                  :label="$t('label.lbl_processingdivision')"
                  class="txt-single"
                  persistent-hint
                  :rules="[rules.inputRequired]"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
              <span class="require" style="float: left">*</span>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
              <!-- 納品日-->
              <div class="date-style" style="float: left">
                <v-text-field
                  outlined
                  dense
                  v-model="dateToDelivery"
                  :label="$t('label.lbl_delDate')"
                  @change="changeDeliveryDate"
                  class="txt-single date-style"
                  clear-icon="mdi-close-circle"
                  clearable
                  :error-messages="alertMessage"
                  :rules="
                    this.isDeliveryDateRequireFlg
                      ? [
                          rules.yyyymmdd,
                          rules.isFutureDate(
                            dateToDelivery,
                            shippingScheduleDate,
                            $t('label.lbl_shippingScheduleDate')
                          ),
                        ]
                      : [
                          rules.yyyymmdd_create,
                          rules.isFutureDate(
                            dateToDelivery,
                            shippingScheduleDate,
                            $t('label.lbl_shippingScheduleDate')
                          ),
                        ]
                  "
                ></v-text-field>
              </div>
              <span
                v-if="this.isDeliveryDateRequireFlg"
                class="require asterisk-spacer"
                style="float: left"
                >*</span
              >

              <div class="calender-btn-area" style="float: left">
                <v-menu
                  v-model="dateToDeliveryMenu"
                  :close-on-content-click="false"
                  :nudge-right="-100"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  class="date-calendar"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" text x-small>
                      <v-icon class="icon-calendar">mdi-calendar</v-icon>
                    </v-btn>
                  </template>
                  <v-date-picker
                    v-model="dateToDeliveryCal"
                    @input="dateToDeliveryMenu = false"
                  ></v-date-picker>
                </v-menu>
                <v-btn
                  id="btn-yesterday"
                  class="day-ctrl-btn"
                  @click="prevDeliveryDate"
                  text
                  x-small
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDeliveryDate" text x-small>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>
          </v-row>
          <v-row>
            <!-- 3段目(表示項目) -->
            <div class="search-row-exeBtn first-search-item" :style="deliverystyle">
              <!-- 届け先名-->
              <div class="search-textbox-remark" style="float: left">
                <v-text-field
                  outlined
                  dense
                  v-model="destinationDisplay"
                  maxlength="200"
                  :label="$t('label.lbl_deliveryname')"
                  class="txt-single"
                  clear-icon="mdi-close-circle"
                  disabled="disabled"
                />
              </div>
              <!-- 届け先名-->
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
              <!-- 届け先住所-->
              <div class="search-textbox-remark" style="float: left">
                <v-text-field
                  outlined
                  dense
                  v-model="destinationAddressDisplay"
                  maxlength="200"
                  :label="$t('label.lbl_popAddress')"
                  class="txt-single"
                  clear-icon="mdi-close-circle"
                  disabled="disabled"
                />
              </div>
              <!-- 届け先住所-->
            </div>
          </v-row>
          <v-row>
            <!-- 3段目 -->
            <div class="search-row-exeBtn first-search-item">
              <!-- 備考欄-->
              <div class="add-textbox-remark" style="float: left">
                <v-text-field
                  outlined
                  dense
                  v-model="remarks"
                  maxlength="200"
                  :label="$t('label.lbl_remarksTextbox')"
                  class="txt-single"
                  clear-icon="mdi-close-circle"
                  clearable
                />
              </div>
              <!-- 空白 -->
              <span class="item-spacer">&nbsp;</span>
              <!--画面クリアボタン-->
              <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>

              <!--ボタンエリア-->
              <div class="btn-area">
                <!-- 個人宅ボタン-->
                <v-btn
                  color="primary"
                  class="api-btn"
                  v-on:click="btnIndividual"
                  :disabled="individualButton"
                >
                  {{ $t("btn.btn_individual") }}
                </v-btn>
                <!-- 個人宅ボタン-->
                <!-- 追加ボタン-->
                <v-btn color="primary" class="api-btn" v-on:click="add" :disabled="isDoAddButton">
                  {{ $t("btn.btn_add") }}
                </v-btn>
                <!-- 登録ボタン-->
                <v-btn color="primary" id="btn-pack" class="api-btn" value="1" @click="shpScheAdd">
                  {{ $t("btn.btn_insert") }}
                </v-btn>
                <ConfirmDialog
                  :isShow.sync="ConfirmDialog.isOpen"
                  :message="ConfirmDialog.message"
                  :okAction="addShpSch"
                  :redMessage="ConfirmDialog.redMessage"
                  :screenFlag="ConfirmDialog.screenFlag"
                  :changeFlag="ConfirmDialog.changeFlag"
                />
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル（項目） -->
      <v-form ref="editedList" lazy-validation>
        <v-container fluid style="padding-bottom: 0px">
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            disable-filtering
            disable-sort
            disable-pagination
            :hide-default-footer="true"
            height="620px"
          >
            <template v-slot:[`item.productCd`]="{ item, index }" style="padding: 0 5px">
              <!-- 商品コード/品名-->
              <v-autocomplete
                v-model="item.productCd"
                :items="item.productList"
                @change="(event) => changeProductNm(event, item, index)"
                :hint="checkProductCd(item, index)"
                :search-input.sync="item.search"
                persistent-hint
                dense
                :ref="'cursorProcd' + index"
                :rules="[$inputRules.isRequiredRow(headerItems, item)]"
                :error-messages="item.requiredItemCheckMsg"
              >
                <option disabled value="item.productCd"></option>
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </template>

            <template v-slot:[`item.productQty`]="{ item, index }">
              <!-- 出荷予定数-->
              <v-text-field
                class="input-number"
                dense
                outlined
                v-model="item.productQty"
                @click="clickPop(item.productQty)"
                @change="changeQty(item, index)"
                :rules="[
                  rules.isNumber,
                  rules.isInteger,
                  rules.isRangeCheck0,
                  rules.limitNumLength,
                ]"
                clear-icon="mdi-close-circle"
                clearable
                :ref="'productQtyCheck' + index"
                :error-messages="item.requiredItemCheckMsg"
              />
            </template>

            <template v-slot:[`item.lot`]="{ item }">
              <!-- LOT-->
              <v-text-field
                class="text-box"
                outlined
                dense
                v-model="item.lot"
                maxlength="20"
                clear-icon="mdi-close-circle"
                clearable
                :disabled="item.lotFlg != '1'"
              />
            </template>
            <!-- S/N -->
            <template v-slot:[`item.serial`]="{ item, index }">
              <div id="table-serial">
                <v-text-field
                  class="text-box"
                  outlined
                  dense
                  v-model="item.serial"
                  maxlength="32"
                  clear-icon="mdi-close-circle"
                  clearable
                  :rules="[rules.serialCheck(item.serial, inputList, index, item.productCd)]"
                  :disabled="item.serialFlg != '1'"
                />
              </div>
            </template>

            <!-- 期限日 -->
            <template v-slot:[`item.dueDate`]="{ item }">
              <div id="table-dueDate">
                <v-text-field
                  class="text-box"
                  outlined
                  dense
                  v-model="item.dueDate"
                  maxlength="8"
                  clear-icon="mdi-close-circle"
                  clearable
                  :rules="[rules.isNumber]"
                  :disabled="item.dueDateFlg != '1'"
                />
              </div>
            </template>

            <template v-slot:[`item.caseSu`]="{ item, index }">
              <!-- ケース数-->
              <v-text-field
                outlined
                dense
                v-model="item.caseSu"
                class="input-number"
                @click="clickPop(item.caseSu)"
                :hint="setHintCase(item, index)"
                @change="changeCase(item, index)"
                persistent-hint
                :rules="[
                  rules.isNumber,
                  rules.isInteger,
                  rules.isRangeCheck0,
                  rules.limitNumLength,
                ]"
                clear-icon="mdi-close-circle"
                clearable
                :ref="'caseSuCheck' + index"
                :error-messages="item.requiredItemCheckMsg"
              />
            </template>

            <template v-slot:[`item.ballSu`]="{ item, index }">
              <!-- ポール数-->
              <v-text-field
                outlined
                dense
                v-model="item.ballSu"
                class="input-number"
                @click="clickPop(item.ballSu)"
                :hint="setHintBall(item, index)"
                @change="changeBall(item, index)"
                persistent-hint
                :rules="[
                  rules.isNumber,
                  rules.isInteger,
                  rules.isRangeCheck0,
                  rules.limitNumLength,
                ]"
                clear-icon="mdi-close-circle"
                clearable
                :ref="'ballSuCheck' + index"
                :error-messages="item.requiredItemCheckMsg"
              />
            </template>

            <template v-slot:[`item.baraSu`]="{ item, index }">
              <!-- バラ数-->
              <v-text-field
                outlined
                dense
                v-model="item.baraSu"
                class="input-number"
                @click="clickPop(item.baraSu)"
                :hint="setHintBara(item, index)"
                @change="changeBara(item, index)"
                persistent-hint
                :rules="[
                  rules.isNumber,
                  rules.isInteger,
                  rules.isRangeCheckBara,
                  rules.limitNumLength,
                ]"
                clear-icon="mdi-close-circle"
                clearable
                :ref="'baraSuCheck' + index"
                :error-messages="item.requiredItemCheckMsg"
              />
            </template>

            <template v-slot:[`item.warehouse`]="{ item, index }">
              <!-- 倉庫-->
              <v-autocomplete
                v-model="item.warehouse"
                :items="warehouseList"
                :hint="checkWarehouse(item)"
                persistent-hint
                dense
                @change="changeWarehouse(item, index)"
                :error-messages="item.warehouseCheckMsg"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </template>

            <template v-slot:[`item.location`]="{ item, index }">
              <!-- ロケ-->
              <v-autocomplete
                v-model="item.location"
                :items="item.locationList"
                :hint="checkLocation(item, index)"
                @change="(event) => changeLocation(event, item, index)"
                :search-input.sync="item.locaSearch"
                persistent-hint
                dense
                :error-messages="item.locationCheckMsg"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </template>

            <template v-slot:[`item.deleteData`]="{ item }">
              <!-- 削除-->
              <v-btn small @click="deleteItem(item)" text>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>

            <!-- 付帯情報/ロケーション予約 -->
            <template v-slot:[`item.accInfos`]="{ item }">
              <div id="table-achieveAdd" v-if="kariFlg">
                <!-- 一旦非表示とする-->
              </div>
              <div v-else>
                <v-btn small @click="openAccData(item)" text>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-truncate" style="max-width: 200px" v-bind="attrs" v-on="on">
                      {{ item.accInfos }}
                    </div>
                  </template>
                  <span>{{ item.accInfos }}</span>
                </v-tooltip>
              </div>
            </template>

            <!-- 付属キー１ -->
            <template v-slot:[`item.lotSubkey1`]="{ item }">
              <div id="table-lotSubkey1">
                <v-text-field
                  class="text-box"
                  outlined
                  dense
                  v-model="item.lotSubkey1"
                  maxlength="20"
                  clear-icon="mdi-close-circle"
                  clearable
                  :disabled="item.lotSubkey1Flg != '1'"
                />
              </div>
            </template>
            <!-- 付属キー２ -->
            <template v-slot:[`item.lotSubkey2`]="{ item }">
              <div id="table-lotSubkey2">
                <v-text-field
                  class="text-box"
                  outlined
                  dense
                  v-model="item.lotSubkey2"
                  maxlength="20"
                  clear-icon="mdi-close-circle"
                  clearable
                  :disabled="item.lotSubkey2Flg != '1'"
                />
              </div>
            </template>
          </v-data-table>
        </v-container>
      </v-form>
      <v-container fluid style="padding-top: 0px">
        <v-data-table
          id="listSumDataSpecial"
          class="tableData"
          fixed-header
          :items="inputSumList"
          :headers="headerItems"
          disable-filtering
          disable-sort
          disable-pagination
          :hide-default-footer="true"
          hide-default-header
          height="49px"
        >
        </v-data-table>
      </v-container>

      <!-- 送り先登録ポップアップ-->
      <v-dialog v-model="dialogDeliveryAdress" @close="close" :max-width="1000">
        <v-card id="dialogDelivery">
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_popDeliveryAddress") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-form ref="destinationList" lazy-validation>
                <v-row>
                  <span class="lbl-screen-name">・届け先</span>
                </v-row>
                <v-row>
                  <!--届け先-名称-->
                  <div class="add-textbox-order-name">
                    <v-text-field
                      outlined
                      dense
                      maxlength="200"
                      v-model="destination"
                      :rules="[rules.inputRequired]"
                      :label="$t('label.lbl_deliveryname')"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <!--届け先-名称-->
                  <span class="require">*</span>
                </v-row>
                <v-row>
                  <!--届け先-郵便番号-->
                  <div class="add-textbox-post">
                    <v-text-field
                      outlined
                      dense
                      maxlength="7"
                      v-model="destinationPostCode"
                      :rules="[rules.inputRequired, rules.isNumber, rules.limiNumLenght7]"
                      :label="$t('label.lbl_postCd')"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <!--届け先-郵便番号-->
                  <span class="require">*</span>
                </v-row>
                <v-row>
                  <!--届け先-住所-->
                  <div class="add-textbox-order-name">
                    <v-text-field
                      outlined
                      dense
                      maxlength="200"
                      v-model="destinationAddress"
                      :rules="[rules.inputRequired]"
                      :label="$t('label.lbl_popAddress')"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <span class="require">*</span>
                  <!--届け先-住所-->
                </v-row>
                <v-row>
                  <!--届け先-電話番号-->
                  <div class="add-textbox-tel">
                    <v-text-field
                      outlined
                      dense
                      maxlength="11"
                      v-model="destinationphoneCode"
                      :rules="[rules.inputRequired, rules.isNumber]"
                      :label="$t('label.lbl_phoneNumber')"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <!--届け先-電話番号-->
                  <span class="require">*</span>
                </v-row>
                <v-row>
                  <!--納品時間-->
                  <div class="add-textbox-tel">
                    <v-text-field
                      outlined
                      dense
                      maxlength="4"
                      v-model="deliveryDate"
                      :rules="[rules.isTime]"
                      :label="$t('label.lbl_delDateTime')"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <!--納品時間-->
                  <span class="item-spacer" style="float: left">&nbsp;</span>
                  <!--購入金額-->
                  <div class="add-textbox-tel">
                    <v-text-field
                      outlined
                      dense
                      v-model="purchaseAmount"
                      class="input-number-modal"
                      :rules="[rules.isNumber, rules.limitNumLength11]"
                      :label="$t('label.lbl_purchase')"
                      @click="clickAmount()"
                      @change="changeAmount()"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <!--購入金額-->
                </v-row>
                <v-row>
                  <span class="lbl-screen-name">・発注者</span>
                  <v-btn
                    color="primary"
                    class="api-btn"
                    style="margin-top: 8px"
                    @click="destinationSet"
                  >
                    {{ $t("btn.btn_destination") }}
                  </v-btn>
                </v-row>
                <v-row>
                  <!--発注者-名称-->
                  <div class="add-textbox-order-name">
                    <v-text-field
                      outlined
                      dense
                      maxlength="200"
                      v-model="ordererName"
                      :rules="[rules.inputRequired]"
                      :label="$t('label.lbl_orderName')"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <span class="require">*</span>
                  <!--発注者-名称-->
                </v-row>
                <v-row>
                  <!--発注者-郵便番号-->
                  <div class="add-textbox-post">
                    <v-text-field
                      outlined
                      dense
                      maxlength="7"
                      v-model="ordererPostCd"
                      :rules="[rules.inputRequired, rules.isNumber, rules.limiNumLenght7]"
                      :label="$t('label.lbl_postCd')"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <span class="require">*</span>
                  <!--発注者-郵便番号-->
                </v-row>
                <v-row>
                  <!--発注者-住所-->
                  <div class="add-textbox-order-name">
                    <v-text-field
                      outlined
                      dense
                      maxlength="200"
                      v-model="ordererAddress"
                      :rules="[rules.inputRequired]"
                      :label="$t('label.lbl_popAddress')"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <span class="require">*</span>
                  <!--発注者-住所-->
                </v-row>
                <v-row>
                  <!--発注者-電話番号-->
                  <div class="add-textbox-tel">
                    <v-text-field
                      outlined
                      dense
                      maxlength="11"
                      v-model="orderphoneNumber"
                      :rules="[rules.inputRequired, rules.isNumber]"
                      :label="$t('label.lbl_phoneNumber')"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <span class="require">*</span>
                  <!--発注者-電話番号-->
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <div class="btn-search-area" style="float: left">
              <v-btn color="primary" class="api-btn" @click="popDeliveryAdressCear">
                {{ "クリア" }}
              </v-btn>
            </div>
            <v-spacer></v-spacer>
            <div class="btn-search-area" style="float: right">
              <v-btn color="primary" class="api-btn" @click="popDeliveryAdressClose">
                {{ $t("btn.btn_close") }}
              </v-btn>
              <v-btn color="primary" class="api-btn" @click="popDeliveryAdressSave">
                {{ $t("btn.btn_insert") }}
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- 付帯情報ポップアップ-->
      <v-dialog v-model="dialogAddProudct" :max-width="800">
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_arrivalScheduleRegistDetail") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    dense
                    v-model="location"
                    :label="$t('label.lbl_location')"
                    clear-icon="mdi-close-circle"
                    clearable
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-btn @click="addLocation()" text>
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-h5"
                  >{{ $t("label.lbl_accessoryInfo") }}
                  <v-btn @click="addAccItem" text>
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <!-- 付帯情報入力箇所 -->
              <v-row>
                <v-data-table
                  hide-default-header
                  :headers="accHeader"
                  :items="accList"
                  disable-filtering
                  disable-pagination
                  disable-sort
                  :hide-default-footer="true"
                >
                  <template v-slot:[`item.accSelected`]="{ item }">
                    <v-autocomplete
                      v-model="item.accSelected"
                      :items="accKeys"
                      @change="(event) => changeAcc(event, item)"
                      outlined
                      dense
                    ></v-autocomplete>
                    <v-text-field v-show="false" v-model="item.accSelectedNm"></v-text-field>
                  </template>
                  <template v-slot:[`item.accValue`]="{ item }">
                    <v-text-field
                      outlined
                      dense
                      v-model="item.accValue"
                      maxlength="10"
                      clear-icon="mdi-close-circle"
                      clearable
                    />
                  </template>
                  <template v-slot:[`item.delete`]="{ item }">
                    <v-btn small @click="deleteAccItem(item)" text>
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="api-btn" @click="close">
              {{ $t("btn.btn_close") }}
            </v-btn>
            <v-btn color="primary" class="api-btn" @click="save">
              {{ $t("btn.btn_insert") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 戻るボタンのダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :shpSchListFlg="infoDialog.shpSchListFlg"
        :homePageFlag="infoDialog.homePageFlag"
        :shpSchAddFlg="infoDialog.shpSchAddFlg"
        :InvResTransitionFlg="infoDialog.InvResTransitionFlg"
        :firstPageFlag="infoDialog.firstPageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
        :okAction="init"
      />
      <v-dialog v-model="dialogLocation" :max-width="1000">
        <LocationDialog
          :isOpenDetailDialog.sync="dialogLocation"
          v-on:setLocation="saveLocation"
          :locInfo="locInfo"
          v-on:closePopup="closePopup"
        />
      </v-dialog>

      <v-dialog v-model="isOpenTruck" :max-width="800">
        <Truck
          :isOpenDetailDialog.sync="isOpenTruck"
          @click="saveTruck"
          :truckInfo="truckInfo"
          ref="truck"
        />
      </v-dialog>
      <CheckDialog
        :isShow.sync="checkDialog.isOpen"
        :message="checkDialog.message"
        :screenFlag="checkDialog.screenFlag"
        :okAction="checkDialog.okAction"
        :redMessage="checkDialog.redMessage"
        :changeFlag="checkDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import ConfirmDialog from "@/components/ConfirmDialog";
import NavBar from "../../components/NavBar.vue";
import SimpleDialog from "@/components/SimpleDialog";
import LocationDialog from "../../views/common/Location";
import Truck from "../../views/common/Truck";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
//import { functionAuth } from "../assets/scripts/js/FunctionAuth";
//import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { i18n } from "../../lang/lang.js";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import CheckDialog from "@/components/ConfirmDialog";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.MENU_ID.P_SHP_SCHEDULE_ADD,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    LocationDialog,
    ConfirmDialog,
    Truck,
    CheckDialog,
    NavBar,
  },
  mixins: [commonMixin, commonRules],
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // 総件数
    totalCount: 0,
    // 一旦非表示するためのフラグ
    kariFlg: true,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 送り先登録対象コード
    sendAddMap: {},
    // 倉庫
    warehouseSelected: "",
    warehouseList: [],
    // ロケ
    locationSelected: "",
    locationList: [],
    warehouse: { warehouse: "", location: "", productCd: "", qty: "" },
    locInfo: {
      locProductCd: "",
      warehouse: "",
      location: "",
      productCd: "",
      qty: 0,
      init: false,
      referenceDate: "",
    },
    // 追加ボタンdisabledフラグ
    isDoAddButton: false,
    //個人宛ボタンdisabledフラグ
    individualButton: true,
    truckInfo: {
      suppliersSelected: "",
      officeSid: "",
      truckInfo: "",
    },
    open: false,
    // ロケーションダイアログ
    dialogLocation: false,
    // ロケーション登録に渡す製品コード
    locProductCd: "",
    // 配送先情報画面に渡す商品コード
    shpProductCd: "",
    // 配送先情報画面ダイアログ
    isOpenTruck: false,
    // 一覧index
    editedIndex: -1,
    // 出荷予定登録ダイアログ
    dialogAddProudct: false,
    // 送り先登録ダイアログ
    dialogDeliveryAdress: false,
    //届け先名(表示用)
    destinationDisplay: "",
    //届け先名(modal)
    destination: "",
    //届け先名(保管)
    shipToName: "",
    //届け先-郵便番号(modal)
    destinationPostCode: "",
    //届け先-郵便番号(保管)
    shipToPostCd: "",
    //届け先-住所(表示用)
    destinationAddressDisplay: "",
    //届け先-住所(modal)
    destinationAddress: "",
    //届け先-住所(保管)
    shipToCharacterName: "",
    //届け先-電話番号(modal)
    destinationphoneCode: "",
    //届け先-電話番号(保管)
    shipToTelephoneNumber: "",
    //納品時間(modal)
    deliveryDate: "",
    //納品時間(保管)
    deliveryTime: "",
    // 納品日必須フラグ
    isDeliveryDateRequireFlg: false,
    //購入金額(modal)
    purchaseAmount: "",
    //購入金額(保管)
    purchasePrice: "",
    //発注者名(modal)
    ordererName: "",
    //発注者名(保管)
    shipFromName: "",
    //発注者-郵便番号(modal)
    ordererPostCd: "",
    //発注者-郵便番号(保管)
    shipFromPostCd: "",
    //発注者-住所(modal)
    ordererAddress: "",
    //発注者-住所(保管)
    shipFromCharacterName: "",
    //発注者-電話番号(modal)
    orderphoneNumber: "",
    //発注者-電話番号(保管)
    shipFromTelephoneNumber: "",
    //送付先表示用
    deliverystyle: "display: none",
    // 処理区分プルダウン
    processDivList: [],
    checkDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // 荷主伝票番号
    txt_slipNo: "",
    // 輸送会社名（画面表示用）
    truckNm: "",
    // 輸送会社区分（登録用）
    truckKbn: "",
    // 輸送会社名（登録用）
    truck: "",
    // 備考欄
    remarks: "",
    // 有償/無償
    isPaid: "01",
    freeCostDivList: [],
    // 責任
    isRes: "01",
    blameDivList: [],
    individualList: [],
    isResBadValue: "02",
    isResBadCount: 0,
    // 出荷先
    shippingSelected: "",
    shippingSelectedCode: "",
    // 処理区分
    processDivSelected: "",
    // 品質区分
    shippingStatusSelected: "01",
    // 無償理由
    freeReasonSelected: "",
    // 取引先
    suppliersSelected: "",
    // 理由コード活性非活性
    isDisabledFreeReason: true,
    // 責任区分活性非活性
    isDisabledBlame: true,
    // メニュー
    openMenu: null,
    // 出荷予定日付キャレンダー
    dateMenu: false,
    dateToDeliveryMenu: false,
    // 出荷予定日付
    shippingScheduleDateCal: "",
    shippingScheduleDate: "",
    // お届け先日付
    dateToDelivery: "",
    dateToDeliveryCal: "",
    // フォーカス移動フラグ
    moveFocusFlag: false,
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // バリデーションチェックメッセージダイアログ
    alertMessage: "",
    //個人宛用コード
    individualCode: "",
    // 必須項目未入力エラーメッセージ
    requiredItemCheckMsg: "",
    // 倉庫未入力エラーメッセージ
    warehouseCheckMsg: "",
    // ロケーション未入力エラーメッセージ
    locationCheckMsg: "",
    // 合計リスト
    inputSumList: [],
    // 初期データ
    defaultData: [],
    // 出荷予定品目一覧ヘッダ
    headerItems: [
      // No
      {
        text: "No",
        value: "No",
        width: "60px",
        sortable: false,
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCd",
        width: "200px",
        align: "center",
        class: "asta",
        sortable: false,
        isRequired: true,
      },
      {
        text: i18n.tc("label.lbl_shippingProductQty"),
        value: "productQty",
        width: "190px",
        align: "center",
        sortable: false,
        class: "asta",
        isRequired: true,
      },
      { text: i18n.tc("label.lbl_lot"), value: "lot", width: "180px", align: "center" },
      { text: i18n.tc("label.lbl_serial"), value: "serial", width: "180px", align: "center" },
      {
        // 期限日
        text: i18n.tc("label.lbl_dueDate"),
        value: "dueDate",
        width: "170px",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_caseSu"),
        value: "caseSu",
        width: "180px",
        align: "center",
        class: "asta",
        isRequired: true,
      },
      {
        text: i18n.tc("label.lbl_ballSu"),
        value: "ballSu",
        width: "180px",
        align: "center",
        class: "asta",
        isRequired: true,
      },
      {
        text: i18n.tc("label.lbl_baraSu"),
        value: "baraSu",
        width: "180px",
        align: "center",
        class: "asta",
        isRequired: true,
      },
      {
        // 倉庫名
        text: i18n.tc("label.lbl_warehouse"),
        value: "warehouse",
        width: "135px",
        align: "center",
      },
      {
        // ロケ
        text: i18n.tc("label.lbl_location"),
        value: "location",
        width: "135px",
        align: "center",
      },
      {
        // 削除
        text: i18n.tc("btn.btn_delete"),
        value: "deleteData",
        width: "74px",
        align: "center",
      },
      {
        // 詳細登録
        text: i18n.tc("label.lbl_arrivalScheduleRegistDetail"),
        value: "accInfos",
        width: "200px",
        align: "center",
      },
      {
        // 付属キー１
        text: i18n.tc("label.lbl_lotSubkey1"),
        value: "lotSubkey1",
        width: "200px",
        align: "center",
      },
      {
        // 付属キー２
        text: i18n.tc("label.lbl_lotSubkey2"),
        value: "lotSubkey2",
        width: "200px",
        align: "center",
      },
    ],
    inputList: [],
    // 取引先プルダウン
    supplierList: [],
    // 無償理由プルダウン
    freeReasonList: [],
    // 出荷先プルダウン
    shippingList: [],
    // 出荷ステータスプルダウン
    shippingStatusList: [],
    defaultItem: {
      No: "",
      accInfos: "",
      productNm: "",
      productCd: "",
      productHint: "",
      locationHint: "",
      productList: [],
      productAcc: "",
      productQty: "",
      caseSu: "",
      caseSuHint: "",
      ballSu: "",
      ballSuHint: "",
      baraSu: "",
      baraSuHint: "",
      lot: "",
      lotFlg: "0",
      serial: "",
      serialFlg: "0",
      dueDate: "",
      dueDateFlg: "0",
      lotSubkey1: "",
      lotSubkey1Flg: "0",
      lotSubkey2: "",
      lotSubkey2Flg: "0",
      location: "",
      locationSid: "",
      // warehouse: { warehouse: "", location: "", productCd: "", qty: "" },// 詳細登録POPUP表示用
      accInfoList: [],
      warehouse: "",
      warehouseList: [],
      locationList: [],
      search: "",
      locaSearch: "",
    },
    // 品番 品名プルダウン
    accHeader: [
      { text: "キー", value: "accSelected", align: "center", width: "20%" },
      { text: "バリュー", value: "accValue", align: "center", width: "70%" },
      { text: "削除", value: "delete", align: "center", width: "10%" },
    ],
    accList: [],
    accKeys: [],
    location: "",
    locationSidList: [],
    // 入荷元取得用
    getSuppliersSelected: "",
    productHintArrival: "",
  }),

  updated() {
    // 画面が再描画されたとき
    if (this.moveFocusFlag) {
      // フォーカス移動
      this.setCursor();
      this.moveFocusFlag = false;
    }
  },

  methods: {
    /*
     * 初期起動：画面を開いた際に渡された値を設定
     */
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);

      if (this.suppliersSelected) {
        // 連続登録の場合
        this.infoDialog.isOpen = false;
        this.shippingStatusSelected = "01";
        this.freeReasonSelected = "";
        this.txt_arrivalScheduleNo = "";
        this.txt_slipNo = "";
        this.remarks = "";
        this.inputList.location = "";
        this.inputList = [];
        this.shippingScheduleDate = dateTimeHelper.convertJST().substr(0, 10);
        this.shippingScheduleDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.dateToDelivery = "";
        this.dateToDeliveryCal = "";
        this.warehouseList = [];
        // 連続登録フラグ
        this.continueAddFlg = true;
        for (var i = 0; i < 10; i++) {
          this.defaultItem.No = i + 1;
          this.inputList.push(Object.assign({}, this.defaultItem));
        }
        this.initialDisplay();
        // バリデーションチェックをリセット
        this.$refs.editedList.resetValidation();
        this.$refs.form.resetValidation();

        // マスタ取得
        this.getMstCode();
        // 倉庫マスタ取得
        this.getWarehouse();
      } else {
        // 初期画面
        this.truckNm = "";
        this.shippingSelected = "";
        this.shippingStatusSelected = "01";
        this.suppliersSelected = "";
        this.freeReasonSelected = "";
        this.txt_arrivalScheduleNo = "";
        this.txt_slipNo = "";
        this.remarks = "";
        this.inputList.location = "";
        this.shippingScheduleDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.shippingScheduleDate = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.dateToDelivery = "";
        this.dateToDeliveryCal = "";
        this.warehouseList = [];
        this.processDivSelected = "01";
        for (var j = 0; j < 10; j++) {
          this.defaultItem.No = j + 1;
          this.defaultItem.warehouseList = this.warehouseList;
          this.defaultItem.locationList = this.locationList;
          this.inputList.push(Object.assign({}, this.defaultItem));
        }
        this.getMstCode();
        // 倉庫マスタ取得
        this.getWarehouse();
      }
      let sumList = [];
      sumList.push({
        No: "",
        productCd: i18n.tc("label.lbl_totalNum") + ":",
        productQty: 0,
        lot: "",
        caseSu: "",
        ballSu: "",
        baraSu: "",
        warehouse: "",
        location: "",
        accInfos: "",
        deleteData: "",
      });
      this.inputSumList = [];
      this.inputSumList = sumList;
    },

    /**
     * 倉庫変更時、ロケ初期化
     */
    changeWarehouse(item, index) {
      item.location = "";
      item.locationHint = "";
      this.inputList[index].locationList = [];
    },

    /**
     * 初期データ取得
     */
    getCustomInfo(jstOutScheduleDate) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstOutScheduleDate)
      );

      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
          // 取引先が1件の場合、取引先を自動選択する
          this.supplierList.length == 1
            ? (this.suppliersSelected = this.supplierList[0].value)
            : "";

          if (this.shippingList.length != 0) {
            // 日付変更時、出荷先リストを取得していた場合再取得
            // this.getSuppliersSelected();
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 品質区分
      const qualityList = getParameter.getCodeMst(appConfig.CODETYPE.OUT_QUALITY_DIV);
      // 理由コード
      const reasonList = getParameter.getCodeMst(appConfig.CODETYPE.OUT_FREEREASON_DIV);
      // 処理区分
      const processList = getParameter.getCodeMst(appConfig.CODETYPE.OUT_PROCESS_DIV);
      // 有償無償区分
      const freeCostList = getParameter.getCodeMst(appConfig.CODETYPE.IN_FREECOST_DIV);
      // 責任区分
      const blameList = getParameter.getCodeMst(appConfig.CODETYPE.IN_BLAME_DIV);
      //個人コード判別用情報
      const individualCodeList = getParameter.getCodeMst(appConfig.CODETYPE.OUT_INDIVIDUAL_DIV);
      Promise.all([
        qualityList,
        reasonList,
        processList,
        freeCostList,
        blameList,
        individualCodeList,
      ])
        .then((result) => {
          // 画面の初期値を設定します。
          this.shippingStatusList = result[0];
          this.freeReasonList = result[1];
          this.processDivList = result[2];
          this.freeCostDivList = result[3];
          // 責任区分の最初のなしを削除
          result[4].splice(0, 1);
          this.blameDivList = result[4];
          this.individualList = result[5];
          // //個人宛コード取得
          // for (var i = 0; i < this.individualList.length; i++) {
          //   if (this.individualList[i].value == "01") {
          //     this.individualCode = this.individualList[i].text;
          //   }
          // }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 出荷予定登録画面：入力カンマ処理 ケース数
     */
    changeCase(val, index) {
      this.inputList[index].caseSu = commonFunction.getDelimiter(val.caseSu, 9);
    },

    //購入金額：カンマセット処理
    changeAmount() {
      this.purchaseAmount = commonFunction.getDelimiterFew(this.purchaseAmount, 11, 0);
    },

    //購入金額：カンマ削除処理
    clickAmount() {
      this.purchaseAmount = commonFunction.getCommaRemoval(this.purchaseAmount);
    },

    //カンマ削除処理
    clickPop(val) {
      let myName = event.target;
      let pop = val;
      if (val != null && val.length > 3) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },

    /**
     * 出荷予定登録画面：入力カンマ処理 ボール数
     */
    changeBall(val, index) {
      this.inputList[index].ballSu = commonFunction.getDelimiter(val.ballSu, 9);
    },

    /**
     * 出荷予定登録画面：入力カンマ処理 バラ数
     */
    changeBara(val, index) {
      this.inputList[index].baraSu = commonFunction.getDelimiter(val.baraSu, 9);
    },

    /**
     * ロケーション予約画面：登録後処理
     */
    saveLocation(data) {
      // 倉庫名
      this.warehouse = data;
      // 画面表示用ロケーション(ロケーションNo + 倉庫名称表示用)
      this.location = data.location + "（" + data.warehouseNameShort + "）";
      // ロケAPI
      this.getLocation();
      // ロケーションSID
      this.locationSid = data.locationSid;
      // ダイアログを閉じる
      this.dialogLocation = false;
    },

    /**
     * ロケーション予約POPUP閉じる
     */
    closePopup() {
      this.dialogLocation = false;
    },

    /**
     * ロケAPI接続
     */
    getLocation(locationSid, warehouseSid) {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();

      // マスタ取得有無(0：通常取得、1：削除済含めすべて取得)
      config.params.isMst = "0";
      // 検索区分(0：共通IF．基準日時 < 有効終了日時 のもののみ ,  1：全件取得)
      config.params.searchCategory = "0";

      if (locationSid == "" || locationSid == null) {
        // 処理なし
      } else {
        config.params.locationSid = locationSid;
      }

      if (warehouseSid == "" || warehouseSid == null) {
        // 処理なし
      } else {
        config.params.warehouseSid = warehouseSid;
      }

      // ソート順
      config.params.reqComSortItem = "location_sid asc";

      // 基準日対応
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC(this.shippingScheduleDateJ);
      // console.debug("getLocation(locationSid, warehouseSid) Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_LOCATION, config)
          .then((response) => {
            // console.debug("getLocation(locationSid, warehouseSid) Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.locations.forEach((row) => {
                list.push({
                  locationNo: row.locationNo,
                  locationSid: row.locationSid,
                  officeNameShort: row.officeNameShort,
                  warehouseSid: row.warehouseSid,
                });
              });
              this.locationSidList = list;
              // ロケ名取得
              this.location = this.locationSidList[0].text;
              resolve(list);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.simpleDialog = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.simpleDialog = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 配送先選択画面：登録後処理
     */
    saveTruck(value) {
      this.truckInfo = value;

      // 画面表示用
      this.truckNm = this.truckInfo.carrierCompName;
      // API検索用
      this.truckKbn = this.truckInfo.carrierCompCd;
      this.truck = this.truckInfo.deliveryTraderSid;
      this.isOpenTruck = false;
    },

    /**
     * 付帯情報POPUP：付帯情報リストボックス取得処理
     */
    changeAcc(value, item) {
      for (var i = 0; i < this.accKeys.length; i++) {
        if (this.accKeys[i].value == value) {
          item.accSelectedNm = this.accKeys[i].text;
          break;
        }
      }
    },

    /**
     * 出荷予定登録画面：商品コード変更時、行データ削除
     */
    changeProductNm(value, item, index) {
      // 既存品番/品名を選択時()
      let val = this.inputList[index].productList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        item.productNm = val.name;
        item.productHint = val.text;
        item.search = val.text;
      }
      item.productList = item.productList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        item.productNm = "";
        item.productHint = "";
        item.serial = "";
        item.serialFlg = "0";
        item.lot = "";
        item.lotFlg = "0";
        item.dueDate = "";
        item.dueDateFlg = "0";
        item.lotSubkey1 = "";
        item.lotSubkey1Flg = "0";
        item.lotSubkey2 = "";
        item.lotSubkey2Flg = "0";
        item.isNew = false;
        item.productQty = null;
        item.caseSu = null;
        item.caseSuHint = null;
        item.ballSu = null;
        item.ballSuHint = null;
        item.baraSu = null;
        item.baraSuHint = null;
        item.locationSid = null;
        item.location = null;
        item.locationHint = null;
        item.warehouseSid = null;
        item.warehouse = null;

        // トータル数の取得
        var totalNum = 0;
        for (var j = 0; j < this.inputList.length; j++) {
          var proNum = commonUtil.zen2han(this.inputList[j].productQty).replaceAll(",", "");

          // 各行の予定数の合計を計算
          totalNum = Number(totalNum) + Number(proNum);
        }
        // カンマ編集
        this.totalCount = commonUtil.formatToCurrency(Number(totalNum));

        let sumList = [];
        sumList.push({
          No: "",
          productCd: i18n.tc("label.lbl_totalNum") + ":",
          productQty: this.totalCount,
          lot: "",
          caseSu: "",
          ballSu: "",
          baraSu: "",
          warehouse: "",
          location: "",
          accInfos: "",
          deleteData: "",
        });
        this.inputSumList = [];
        this.inputSumList = sumList;
      } else {
        // 処理なし
      }
    },

    /**
     * 付帯情報/ロケーション予約POPUP：付帯情報/ロケーション予約POPUP処理
     */
    openAccData(item) {
      // 選択した明細のインデックスを取得
      this.editedIndex = this.inputList.indexOf(item);

      // indexから付帯情報を取得する。
      let accInfoList = this.inputList[this.editedIndex].accInfoList;

      // 付帯情報を表示用付帯情報に設定する。
      this.accList = accInfoList;

      // ロケーションを取得し設定する。
      let location = this.inputList[this.editedIndex].location;
      this.location = location;

      let locationSid = this.inputList[this.editedIndex].locationSid;
      this.locationSid = locationSid;

      // マスタを取得する。
      this.getMaster(item);

      this.dialogAddProudct = true;
    },

    /**
     * 付帯情報POPUP：登録処理
     */
    save() {
      // ツールチップの値
      var accInfos = this.setItemToList();
      // 付帯情報の名前のリスト
      var accInfoItem = this.setItemTo();
      // 付帯情報の値のリスト
      var accValue = this.setItemCon();

      // 付帯情報リストをクリアする
      this.accList.splice(0);

      // 付帯情報の名前と値のリストを作成する
      let ancillaryInfoList = [];

      // 付帯情報分リストを回す
      for (var i = 0; i < accInfoItem.length; i++) {
        // 付帯情報保持データ
        let ancillaryInfo = { accSelected: "", accSelectedNm: "" };

        // 名前
        ancillaryInfo.accSelected = accInfoItem[i];
        ancillaryInfo.accSelectedNm = accInfoItem[i];

        // 値
        ancillaryInfo.accValue = accValue[i];

        // 付帯情報の名前と値のリストに追加
        ancillaryInfoList.push(ancillaryInfo);
      }

      // 画面表示用付属情報（ツールチップ）
      this.inputList[this.editedIndex].accInfos = accInfos;

      // 付帯情報を設定
      this.inputList[this.editedIndex].accInfoList = ancillaryInfoList;

      // 値渡し用ロケリスト
      this.inputList[this.editedIndex].location = this.location;
      this.inputList[this.editedIndex].locationSid = this.locationSid;

      this.close();
    },

    /**
     * 出荷予定登録画面：責任区別活性化処理
     */
    activateButton() {
      if (this.shippingStatusSelected == "") {
        return true;
      } else if (this.shippingStatusSelected == "01") {
        return true;
      } else if (this.shippingStatusSelected == "02") {
        return false;
      }
    },

    /**
     * 出荷予定登録画面：無償理由活性化処理
     */
    activateFreeReason() {
      if (this.isPaid == "02") {
        return false;
      } else {
        return true;
      }
    },

    /**
     * 出荷予定登録画面：出荷予定日付を－１日
     */
    prevDate() {
      this.shippingScheduleDateCal = commonFunction.addDate(this.shippingScheduleDateCal, "");
    },

    /**
     * 出荷予定登録画面：出荷予定日付を＋１日
     */
    nextDate() {
      this.shippingScheduleDateCal = commonFunction.addDate(this.shippingScheduleDateCal, "add");
    },
    /**
     * 出荷予定登録画面：出荷予定日付を－１日
     */
    prevDeliveryDate() {
      this.dateToDeliveryCal = commonFunction.addDate(this.dateToDeliveryCal, "");
    },

    /**
     * 出荷予定登録画面：出荷予定日付を＋１日
     */
    nextDeliveryDate() {
      this.dateToDeliveryCal = commonFunction.addDate(this.dateToDeliveryCal, "add");
    },

    /**
     * 出荷予定登録画面：yyyy/MM/ddに変換処理（カレンダコンポーネントの日付はyyyy-MM-ddのため）
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    /**
     * 出荷予定登録画面：付帯情報ツールチップ表示処理
     */
    setItemToList() {
      // 付帯情報詳細処理
      const accInfos = [];
      for (var i = 0; i < this.accList.length; i++) {
        accInfos.push(this.accList[i].accSelectedNm + ":" + this.accList[i].accValue);
      }
      return accInfos.join("\n");
    },

    /**
     * 出荷予定登録画面：付帯情報値変換処理(項目)
     */
    setItemTo() {
      // 付帯情報詳細処理
      const accInfoItem = [];
      for (var i = 0; i < this.accList.length; i++) {
        accInfoItem.push(this.accList[i].accSelectedNm);
      }
      return accInfoItem;
    },

    /**
     * 入荷予定登録画面：付帯情報値変換処理(内容)
     */
    setItemCon() {
      // 付帯情報詳細処理
      const accInfoCon = [];
      for (var i = 0; i < this.accList.length; i++) {
        accInfoCon.push(this.accList[i].accValue);
      }
      return accInfoCon;
    },

    /**
     * 出荷予定登録画面：行削除処理
     */
    deleteItem(item) {
      this.editedIndex = this.inputList.indexOf(item);
      this.inputList.splice(this.editedIndex, 1);

      for (var i = 0; i < this.inputList.length; i++) {
        this.inputList[i].No = i + 1;
      }
      // console.debug("行削除");

      var proQty = commonUtil.zen2han(item.productQty).replaceAll(",", "");
      var totalNum = commonUtil.zen2han(this.totalCount).replaceAll(",", "");

      // 予定数合計から削除したレコードの数を引く
      this.totalCount = Number(totalNum) - Number(proQty);
      // カンマ編集
      this.totalCount = commonUtil.formatToCurrency(Number(this.totalCount));
      let sumList = [];
      sumList.push({
        No: "",
        productCd: i18n.tc("label.lbl_totalNum") + ":",
        productQty: this.totalCount,
        lot: "",
        caseSu: "",
        ballSu: "",
        baraSu: "",
        warehouse: "",
        location: "",
        accInfos: "",
        deleteData: "",
      });
      this.inputSumList = [];
      this.inputSumList = sumList;

      this.$refs["productQtyCheck" + this.editedIndex].resetValidation();
      this.$refs["caseSuCheck" + this.editedIndex].resetValidation();
      this.$refs["ballSuCheck" + this.editedIndex].resetValidation();
      this.$refs["baraSuCheck" + this.editedIndex].resetValidation();
    },

    /**
     * 出荷予定登録画面：取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      return commonFunction.getListSetName(this.supplierList, this.suppliersSelected);
    },

    /**
     * 出荷予定登録画面：出荷先コードリストボックス取得処理
     */
    setSrhShipping() {
      return commonFunction.getListSetName(this.shippingList, this.shippingSelected);
    },

    /**
     * 出荷予定登録画面：付帯情報遷移処理
     */
    openAcc(item) {
      // 選択した明細のインデックスを取得
      this.editedIndex = this.inputList.indexOf(item);

      // indexから付帯情報を取得する。
      let accInfoList = this.inputList[this.editedIndex].accInfoList;

      // 付帯情報を表示用付帯情報に設定する。
      this.accList = accInfoList;

      // マスタを取得する。
      this.getMaster(item);

      this.dialogAddProudct = true;
    },

    /**
     * 付帯情報POPUP：閉じる処理
     */
    close() {
      this.dialogAddProudct = false;
      this.editedIndex = -1;
    },

    /**
     * 付帯情報/ロケーション予約POPUP：行削除処理
     */
    deleteAccItem(item) {
      this.editedIndex = this.accList.indexOf(item);
      this.accList.splice(this.editedIndex, 1);
    },

    /**
     * 出荷予定登録画面：商品コードリストボックス取得処理
     */
    checkProductCd(value, index) {
      //S/N活性・非活性判定
      for (var i = 0; i < this.inputList[index].productList.length; i++) {
        if (this.inputList[index].productList[i].value == this.inputList[index].productCd) {
          this.inputList[index].serialFlg = this.inputList[index].productList[i].serialFlg;
          this.inputList[index].lotFlg = this.inputList[index].productList[i].lotFlg;
          this.inputList[index].dueDateFlg = this.inputList[index].productList[i].dueDateFlg;
          this.inputList[index].lotSubkey1Flg = this.inputList[index].productList[i].lotSubkey1Flg;
          this.inputList[index].lotSubkey2Flg = this.inputList[index].productList[i].lotSubkey2Flg;
        }
        if (this.inputList[index].serialFlg == "0") {
          this.inputList[index].serial = "";
        }
        if (this.inputList[index].lotFlg == "0") {
          this.inputList[index].lot = "";
        }
        if (this.inputList[index].dueDateFlg == "0") {
          this.inputList[index].dueDate = "";
        }
        if (this.inputList[index].lotSubkey1Flg == "0") {
          this.inputList[index].lotSubkey1 = "";
        }
        if (this.inputList[index].lotSubkey2Flg == "0") {
          this.inputList[index].lotSubkey2 = "";
        }
      }
      return this.inputList[index].productHint;
    },

    /**
     * 出荷予定登録画面：ケース数計算処理
     */
    setHintCase(value, index) {
      return this.inputList[index].caseSuHint;
    },

    /**
     * 出荷予定登録画面：ボール数計算処理
     */
    setHintBall(value, index) {
      return this.inputList[index].ballSuHint;
    },

    /**
     * 出荷予定登録画面：バラ数計算処理
     */
    setHintBara(value, index) {
      return this.inputList[index].baraSuHint;
    },

    /**
     * 出荷予定登録画面：行追加処理
     */
    add() {
      // 追加ボタンをdisabledに
      this.isDoAddButton = true;

      if (this.inputList.length != 0) {
        this.focusIdx = this.inputList[this.inputList.length - 1].No;
      } else {
        this.focusIdx = 0;
      }

      for (var i = 0; i < 10; i++) {
        this.defaultItem.No = this.focusIdx + i + 1;
        this.inputList.push(Object.assign({}, this.defaultItem));
      }
      // フォーカス移動フラグをONに
      this.moveFocusFlag = true;
      // xミリ秒待機したのちに、追加ボタンをenabledに
      setTimeout(this.enabledAddButton, 500);
    },

    /**
     * 追加ボタンを有効化する
     */
    enabledAddButton() {
      this.isDoAddButton = false;
    },
    /**
     * 出荷予定登録画面：出荷予定数、入力カンマ処理
     */
    changeQty(val, index) {
      // カンマを除去して数値項目に変換
      var proQty = commonFunction.getCommaRemoval(val.productQty);
      // inputListの商品コードを取得
      let inputListProductCd = this.inputList[index].productCd;

      // 選択した商品コードがproductListに何番目に入っているかを調べる。
      let selectedProductQuantity = 0;
      for (var i = 0; i < this.inputList[index].productList.length; i++) {
        if (inputListProductCd == this.inputList[index].productList[i].value) {
          selectedProductQuantity = this.inputList[index].productList[i].quantity;
        }
      }

      // 各数量項目の計算処理
      if (!isNaN(Number(proQty))) {
        this.inputList[index].productQty = commonUtil.formatToCurrency(Number(proQty));
        if (0 < selectedProductQuantity) {
          // 入荷予定数が入力されている場合
          this.inputList[index].caseSu = commonUtil.formatToCurrency(
            Math.ceil(proQty / selectedProductQuantity)
          );
          this.inputList[index].caseSuHint = commonUtil.formatToCurrency(
            Math.ceil(proQty / selectedProductQuantity)
          );
          this.inputList[index].baraSu = commonUtil.formatToCurrency(
            proQty % selectedProductQuantity
          );
          this.inputList[index].baraSuHint = commonUtil.formatToCurrency(
            proQty % selectedProductQuantity
          );
          this.inputList[index].ballSu = commonUtil.formatToCurrency(
            Number(selectedProductQuantity)
          );
          this.inputList[index].ballSuHint = commonUtil.formatToCurrency(
            Number(selectedProductQuantity)
          );
          // カンマ編集して数量にセット
          this.inputList[index].productQty = commonUtil.formatToCurrency(Number(proQty));
        } else {
          this.inputList[index].caseSu = "0";
          this.inputList[index].ballSu = "0";
          this.inputList[index].baraSu = "0";
        }
      }

      var totalNum = 0;
      this.totalCount = 0;
      if (!isNaN(Number(proQty)) || Number(totalNum)) {
        for (var j = 0; j < this.inputList.length; j++) {
          var proNum = commonUtil.zen2han(this.inputList[j].productQty).replaceAll(",", "");

          // 各行の予定数の合計を計算
          totalNum = Number(totalNum) + Number(proNum);
        }
        // カンマ編集
        this.totalCount = commonUtil.formatToCurrency(Number(totalNum));
      }

      let sumList = [];
      sumList.push({
        No: "",
        productCd: i18n.tc("label.lbl_totalNum") + ":",
        productQty: this.totalCount,
        lot: "",
        caseSu: "",
        ballSu: "",
        baraSu: "",
        warehouse: "",
        location: "",
        accInfos: "",
        deleteData: "",
      });
      this.inputSumList = [];
      this.inputSumList = sumList;
    },

    /**
     * ロケーション予約画面処理
     */
    addLocation() {
      if (this.inputList[this.editedIndex].warehouse == {}) {
        this.locInfo.warehouse = "";
        this.locInfo.location = "";
        this.locInfo.productCd = "";
        this.locInfo.qty = 0;
        this.locInfo.init = true;
        this.locInfo.referenceDate = dateTimeHelper.convertUTC(this.shippingScheduleDate);
      } else {
        this.locInfo.warehouse = this.inputList[this.editedIndex].warehouse.warehouse;
        this.locInfo.location = this.inputList[this.editedIndex].warehouse.location;
        this.locInfo.productCd = this.inputList[this.editedIndex].warehouse.productCd;
        this.locInfo.qty = this.inputList[this.editedIndex].warehouse.qty;
        this.locInfo.init = false;
        this.locInfo.referenceDate = dateTimeHelper.convertUTC(this.shippingScheduleDate);
      }
      this.locInfo.referenceDate = dateTimeHelper.convertUTC(this.shippingScheduleDate);
      this.locInfo.locProductCd = this.inputList[this.editedIndex].productCd;
      this.dialogLocation = true;
    },

    /**
     * 出荷予定登録画面：日付入力処理
     */
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.shippingScheduleDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.shippingScheduleDateCal = null;
      }
    },
    /**
     * 出荷予定登録画面：お届け先日付入力処理
     */
    changeDeliveryDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.dateToDeliveryCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.dateToDeliveryCal = null;
      }
    },

    /**
     * 送り先登録画面：登録処理
     */
    popDeliveryAdressClose() {
      this.dialogDeliveryAdress = false;
    },

    /**
     * 配送先選択画面：配送先選択ボタン処理
     */
    openTruck() {
      if (this.suppliersSelected) {
        //取引先SID
        this.truckInfo.suppliersSelected = this.suppliersSelected;
        //路線業者SID
        this.truckInfo.deliveryTraderSid = this.truck;
        //輸送業者コード
        this.truckInfo.carrierCompCd = this.truckKbn;
        this.isOpenTruck = true;
        if (this.$refs.truck != undefined) {
          //表示時処理
          this.$refs.truck.init();
        }
      } else {
        this.suppliersSelected = null;
      }
    },

    /**
     * 付帯情報POPUP：行追加処理
     */
    addAccItem() {
      this.accList.push({ accSelected: "", accValue: "" });
    },

    /**
     * 出荷予定登録画面：個人宛ボタン押下時
     */
    btnIndividual() {
      this.modalset();
      this.dialogDeliveryAdress = true;
    },

    /**
     * 個人宛情報-保管の値をmodalへセット
     */
    modalset() {
      this.$refs.destinationList.resetValidation();
      //保管の値をmodalへセット---------------------------------------------//
      //届け先名
      this.destination = this.shipToName;
      //届け先-郵便番号
      this.destinationPostCode = this.shipToPostCd;
      //届け先-住所
      this.destinationAddress = this.shipToCharacterName;
      //届け先-電話番号
      this.destinationphoneCode = this.shipToTelephoneNumber;
      //納品時間
      this.deliveryDate = this.deliveryTime;
      //購入金額
      this.purchaseAmount = this.purchasePrice;
      //発注者名
      this.ordererName = this.shipFromName;
      //発注者-郵便番号
      this.ordererPostCd = this.shipFromPostCd;
      //発注者-住所
      this.ordererAddress = this.shipFromCharacterName;
      //発注者-電話番号
      this.orderphoneNumber = this.shipFromTelephoneNumber;
      //modalの値を保管へセット---------------------------------------------//
    },

    /**
     * 個人宛情報初期化
     */
    initializationdestination() {
      //「個人宅」ボタン非活性
      this.individualButton = true;
      //3行目非表示
      this.deliverystyle = "display: none";
      this.initialDisplay();
    },

    initialDisplay() {
      //個人宛情報各項目初期化
      //届け先名(表示用)
      this.destinationDisplay = "";
      //届け先名(保管)
      this.shipToName = "";
      //届け先-郵便番号(保管)
      this.shipToPostCd = "";
      //届け先-住所(表示用)
      this.destinationAddressDisplay = "";
      //届け先-住所(保管)
      this.shipToCharacterName = "";
      //届け先-電話番号(保管)
      this.shipToTelephoneNumber = "";
      //納品時間(保管)
      this.deliveryTime = "";
      //購入金額(保管)
      this.purchasePrice = "";
      //発注者名(保管)
      this.shipFromName = "";
      //発注者-郵便番号(保管)
      this.shipFromPostCd = "";
      //発注者-住所(保管)
      this.shipFromCharacterName = "";
      //発注者-電話番号(保管)
      this.shipFromTelephoneNumber = "";
      this.popDeliveryAdressCear();
    },

    /**
     * クリアボタン押下時
     */
    popDeliveryAdressCear() {
      //届け先名(modal)
      this.destination = "";
      //届け先-郵便番号(modal)
      this.destinationPostCode = "";
      //届け先-住所(modal)
      this.destinationAddress = "";
      //届け先-電話番号(modal)
      this.destinationphoneCode = "";
      //納品時間(modal)
      this.deliveryDate = "";
      //購入金額(modal)
      this.purchaseAmount = "";
      //発注者名(modal)
      this.ordererName = "";
      //発注者-郵便番号(modal)
      this.ordererPostCd = "";
      //発注者-住所(modal)
      this.ordererAddress = "";
      //発注者-電話番号(modal)
      this.orderphoneNumber = "";
    },
    /**
     * 「届け先と同様ボタン押下時
     */
    destinationSet() {
      //届け先名を発注者名へセット
      this.ordererName = this.destination;
      //届け先-郵便番号を発注者-郵便番号へセット
      this.ordererPostCd = this.destinationPostCode;
      //届け先-住所を発注者-住所へセット
      this.ordererAddress = this.destinationAddress;
      //届け先-電話番号を発注者-電話番号へセット
      this.orderphoneNumber = this.destinationphoneCode;
    },

    /**
     * 個人宛情報登録
     */
    popDeliveryAdressSave() {
      if (this.$refs.destinationList.validate()) {
        //ヘッダの表示項目にセット(届け先名)
        this.destinationDisplay = this.destination;
        //ヘッダの表示項目にセット(届け先名住所)
        this.destinationAddressDisplay = this.destinationAddress;
        //modalの値を保管へセット---------------------------------------------//
        //届け先名
        this.shipToName = this.destination;
        //届け先-郵便番号
        this.shipToPostCd = this.destinationPostCode;
        //届け先-住所
        this.shipToCharacterName = this.destinationAddress;
        //届け先-電話番号
        this.shipToTelephoneNumber = this.destinationphoneCode;
        //納品時間
        this.deliveryTime = this.deliveryDate;
        //購入金額
        this.purchasePrice = this.purchaseAmount;
        //発注者名
        this.shipFromName = this.ordererName;
        //発注者-郵便番号
        this.shipFromPostCd = this.ordererPostCd;
        //発注者-住所
        this.shipFromCharacterName = this.ordererAddress;
        //発注者-電話番号
        this.shipFromTelephoneNumber = this.orderphoneNumber;
        //modalの値を保管へセット---------------------------------------------//
        //個人宛情報非表示
        this.dialogDeliveryAdress = false;
        //登録フラグ
        this.individualAddFlg = true;
      }
    },

    /**
     * 出荷予定登録画面：有償押下処理
     */
    paidReason() {
      this.activateFreeReason();
    },

    /**
     * 出荷予定登録画面：カーソル位置処理
     */
    setCursor() {
      let listLength = this.inputList.length;
      let cursolPosition = String(listLength - 10);
      eval("this.$refs.cursorProcd" + cursolPosition + ".focus()");
    },

    /**
     * 出荷予定登録画面：登録ボタン押下
     */
    shpScheAdd() {
      //エラー内容初期化処理--------------------------------------------------
      this.loadingCounter = 1;
      for (let i = 0; i < this.inputList.length; i++) {
        this.inputList[i].requiredItemCheckMsg = i18n.tc("");
        this.inputList[i].warehouseCheckMsg = i18n.tc("");
        this.inputList[i].locationCheckMsg = i18n.tc("");
      }
      this.loadingCounter = 0;
      //エラー内容初期化処理--------------------------------------------------
      let cnt = 0;
      let countCheckFlg = true;
      this.alertMessage = "";
      // 伝票部、入力チェック実施
      if (this.$refs.form.validate() && this.$refs.editedList.validate()) {
        //個人宛ボタンが有効の場合
        if (!this.individualButton) {
          this.modalset();
          if (!this.$refs.destinationList.validate()) {
            console.log("問題あり");
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-RCV-001_007_E");
            return;
          }
        }
        // 明細部未入力チェック準備
        let checkList = []; // 明細部チェックリスト
        for (let i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].productCd) {
            // 明細部の品番品名が入力されている場合はリストに追加
            checkList.push(this.inputList[i].productCd);
          } else {
            // 上記以外の場合は処理なし。
          }
        }
        if (
          // FromToの日付、入力チェック
          this.dateToDelivery != null &&
          new Date(this.shippingScheduleDate).getTime() > new Date(this.dateToDelivery).getTime()
        ) {
          this.alertMessage = i18n.tc("check.chk_inputDeliveryVali");
          return;
        } else {
          // 処理なし
        }

        var errNo = false;
        for (let i = 0; i < this.inputList.length; i++) {
          let count = 0;
          // 品番の入力有無チェック
          if (this.inputList[i].productCd != null && this.inputList[i].productCd != "") {
            count++;
          }
          // 出荷予定数の入力有無チェック
          if (this.inputList[i].productQty != null && this.inputList[i].productQty != "") {
            count++;
          }
          // ロットの入力有無チェック
          if (this.inputList[i].lot != null && this.inputList[i].lot != "") {
            count++;
          }
          // ケース数の入力有無チェック
          if (this.inputList[i].caseSu != null && this.inputList[i].caseSu != "") {
            count++;
          }
          // ボール数の入力有無チェック
          if (this.inputList[i].ballSu != null && this.inputList[i].ballSu != "") {
            count++;
          }
          // バラ数の入力有無チェック
          if (this.inputList[i].baraSu != null && this.inputList[i].baraSu != "") {
            count++;
          }
          // 倉庫の入力有無チェック
          if (this.inputList[i].warehouse != null && this.inputList[i].warehouse != "") {
            count++;
          }
          // ロケの入力有無チェック
          if (this.inputList[i].location != null && this.inputList[i].location != "") {
            count++;
          }
          if (count > 0) {
            // 必須項目が未入力のチェック
            if (
              this.inputList[i].productCd == null ||
              this.inputList[i].productCd == "" ||
              this.inputList[i].productQty == null ||
              this.inputList[i].productQty == "" ||
              this.inputList[i].caseSu == null ||
              this.inputList[i].caseSu == "" ||
              this.inputList[i].ballSu == null ||
              this.inputList[i].ballSu == "" ||
              this.inputList[i].baraSu == null ||
              this.inputList[i].baraSu == ""
            ) {
              // 未入力の明細Noの取得
              errNo = true;
              // 未入力の必須項目エラーメッセージ
              this.inputList[i].requiredItemCheckMsg = i18n.tc("check.chk_input");
            }
          }
          // 倉庫ロケーションの相関チェック(倉庫、ロケいずれかが入力されていた場合は両方入力されていないとNG)
          // 倉庫
          let warehouse = this.inputList[i].warehouse;
          // ロケ
          let location = this.inputList[i].location;

          // 入力チェック
          if ((warehouse == null || warehouse == "") && (location == null || location == "")) {
            // 倉庫とロケが両方入力されていない場合は処理なし
          } else {
            // 上記以外の場合
            if (warehouse != null && warehouse != "" && location != null && location != "") {
              // 倉庫ロケ両方入力されている場合は処理なし
            } else {
              if (warehouse == null || warehouse == "") {
                // ロケーションのみ入力されている場合
                this.inputList[i].warehouseCheckMsg = i18n.tc("check.chk_input");
                errNo = true;
              } else {
                // 上記以外の場合（倉庫のみ入力されている場合）
                this.inputList[i].locationCheckMsg = i18n.tc("check.chk_input");
                errNo = true;
              }
            }
          }
        }

        if (errNo) {
          return;
        }

        // 明細部の未入力チェック
        if (checkList.length <= 0) {
          // 上記でのチェックリストが0件の場合は、警告メッセージ表示
          this.updateDialogMessage = true;
          this.backMessage = messsageUtil.getMessage("P-RCV-001_006_E");
          cnt = i + 1;
          countCheckFlg = true;
        } else {
          // 処理なし
        }

        // 明細部、入力チェック実施
        for (var i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].productCd) {
            var checkQtyNum = Number(
              commonUtil.zen2han(this.inputList[i].productQty).replaceAll(",", "")
            );
            var checkCaseNum = Number(
              commonUtil.zen2han(this.inputList[i].caseSu).replaceAll(",", "")
            );
            var checkBaraNum = Number(
              commonUtil.zen2han(this.inputList[i].baraSu).replaceAll(",", "")
            );
            var checkBallNum = Number(
              commonUtil.zen2han(this.inputList[i].ballSu).replaceAll(",", "")
            );

            if (checkQtyNum == 0) {
              // 出荷予定数が0の場合、警告メッセージ表示
              this.updateDialogMessage = true;
              this.backMessage = messsageUtil.getMessage("P-SHP-003_001_E");
              cnt = i + 1;
              countCheckFlg = true;
            } else {
              // 処理なし
            }

            if (checkQtyNum == "") {
              // 入荷予定数が未入力時
              cnt = i + 1;
            } else {
              // 処理なし
            }

            // 数値チェック
            if (
              isNaN(checkQtyNum) ||
              isNaN(checkBallNum) ||
              isNaN(checkCaseNum) ||
              isNaN(checkBaraNum)
            ) {
              // 出荷予定数、ボール数、ケース数、バラ数が数値でない時
              cnt = i + 1;
            } else {
              // 処理なし
            }

            // 整数チェック
            if (
              Number.isInteger(checkQtyNum) &&
              Number.isInteger(checkBallNum) &&
              Number.isInteger(checkCaseNum) &&
              Number.isInteger(checkBaraNum)
            ) {
              // 処理なし
            } else {
              // 出荷予定数、ボール数、ケース数、バラ数が整数でない時
              cnt = i + 1;
            }

            // 範囲チェック（0未満）
            if (0 < checkQtyNum && 0 < checkBallNum && 0 < checkCaseNum && 0 <= checkBaraNum) {
              // 処理なし
            } else {
              // 出荷予定数、ボール数、ケース数、バラ数が0未満の場合
              cnt = i + 1;
            }
          }
        }
        // 明細部、入力エラーなし（カウント0）の場合、確認メッセージ表示
        if (cnt == 0 && countCheckFlg) {
          if (this.isPaid == "02") {
            this.ConfirmDialog.screenFlag = false;
            this.ConfirmDialog.changeFlag = true;
            this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_004_C");
            this.ConfirmDialog.redMessage = "無償";
            this.ConfirmDialog.title = "警告";
            this.ConfirmDialog.isOpen = true;
          } else {
            this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
            this.ConfirmDialog.isOpen = true;
            this.ConfirmDialog.screenFlag = true;
            this.ConfirmDialog.changeFlag = false;
          }
        } else {
          // 明細部、入力エラー時（カウント1）
          if (this.$refs.editedList.validate()) {
            // 処理なし
          } else {
            for (var j = 0; j < this.inputList.length; j++) {
              let checkPosition = String(j);
              if (!this.inputList[j].productCd) {
                // 関係ないレコードの入力チェックエラーを解除
                eval("this.$refs.productQtyCheck" + checkPosition + ".resetValidation()");
                eval("this.$refs.baraSuCheck" + checkPosition + ".resetValidation()");
                eval("this.$refs.caseSuCheck" + checkPosition + ".resetValidation()");
                eval("this.$refs.ballSuCheck" + checkPosition + ".resetValidation()");
              } else {
                // 処理なし
              }
            }
          }
        }
      } else {
        // 伝票部、入力チェックエラーの場合、処理なし
      }
    },

    /**
     * 出荷予定数0ダイアログ、OK
     */
    updateOk() {
      this.updateDialogMessage = false;
    },

    /**
     * 出荷予定登録画面： 付属情報_商品付属マスタAPI(GET)
     */
    getMaster(item) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      this.editedIndex = this.inputList.indexOf(item);

      // 商品付帯情報
      const itemsIndividual = getParameter.getItemIndividual(
        this.inputList[this.editedIndex].productCd
      );

      Promise.all([itemsIndividual])
        .then((result) => {
          // 画面の初期値を設定します。
          this.accKeys = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 出荷予定登録画面：出荷予定API（POST）
     */
    addShpSch() {
      // メッセージ初期化
      this.alertMessage = "";
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();
      // 共通IF項目 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_EXT_001;

      // 出荷予定明細List
      let bodyList = [];

      for (let i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].productCd != "" && this.inputList[i].productCd != null) {
          let inputMap = {};
          inputMap.outListSubNo = this.inputList[i].No;
          inputMap.itemStandardSid = this.inputList[i].productCd;

          for (let m = 0; m < this.inputList[i].productList.length; m++) {
            if (this.inputList[i].productList[m].value == this.inputList[i].productCd) {
              inputMap.itemCd = this.inputList[i].productList[m].code;
            }
          }
          inputMap.deleteFlg = "0";
          // ロット
          inputMap.lotNo = commonUtil.createLotNo(
            this.inputList[i].lot,
            this.inputList[i].serial,
            this.inputList[i].dueDate,
            this.inputList[i].lotSubkey1,
            this.inputList[i].lotSubkey2
          );
          inputMap.shipScheduleQuantity = this.inputList[i].productQty.replaceAll(",", "");
          inputMap.shipPackingQuantity = this.inputList[i].caseSu.replaceAll(",", "");
          inputMap.shipUnitQuantity = this.inputList[i].ballSu.replaceAll(",", "");
          inputMap.fracQuantity = this.inputList[i].baraSu.replaceAll(",", "");
          // 品質区分
          inputMap.qualityDiv = this.shippingStatusSelected;
          // 情報分類明細List
          this.infoCategoryDtl = [];
          if (this.inputList[i].accInfoList) {
            for (let j = 0; j < this.inputList[i].accInfoList.length; j++) {
              let accInfoMap = {};
              accInfoMap.subInformation = this.inputList[i].accInfoList[j].accSelected;
              accInfoMap.subInformationComment = this.inputList[i].accInfoList[j].accValue;
              this.infoCategoryDtl.push(accInfoMap);
            }
          }

          // ロケーション
          if (this.inputList[i].location) {
            inputMap.locationSid = this.inputList[i].location;

            for (let m = 0; m < this.inputList[i].locationList.length; m++) {
              if (this.inputList[i].locationList[m].value == this.inputList[i].location) {
                inputMap.locationNo = this.inputList[i].locationList[m].name;
              }
            }
          }

          // ロケ予約List
          this.locationReserve = [];
          if (this.inputList[i].location) {
            let warehouseMap = {};
            warehouseMap.locationSid = this.inputList[i].location;
            this.locationReserve.push(warehouseMap);
          }
          // // ロケ予約List(詳細登録POPUP用)
          // this.locationReserve = [];
          // if (this.inputList[i].location) {
          //   var warehouseMap = {};
          //   warehouseMap.locationSid = this.inputList[i].locationSid;
          //   this.locationReserve.push(warehouseMap);
          // }
          inputMap.locationReserve = this.locationReserve;
          inputMap.infoCategoryDtl = this.infoCategoryDtl;

          bodyList.push(inputMap);
        }
      }

      body.reqIdv.deleteFlg = "0";
      body.reqIdv.officeSid = sessionStorage.getItem("sales_office_sid");
      body.reqIdv.clientSid = this.suppliersSelected;
      body.reqIdv.remarks = this.remarks;
      body.reqIdv.outListNo = this.txt_slipNo;
      body.reqIdv.outScheduleDate = dateTimeHelper.convertUTC(this.shippingScheduleDate);
      // 処理区分
      body.reqIdv.processDiv = this.processDivSelected;
      body.reqIdv.modifyDiv = "01";
      body.reqIdv.reasonCd = this.freeReasonSelected;
      body.reqIdv.freeCostDiv = this.isPaid;
      body.reqIdv.qualityDiv = this.shippingStatusSelected;
      body.reqIdv.blameDiv = this.isRes;
      body.reqIdv.fromSid = sessionStorage.getItem("sales_office_sid");
      body.reqIdv.toSid = this.shippingSelected;
      body.reqIdv.businessSid = "";
      body.reqIdv.deliveryDate = this.dateToDelivery;
      body.reqIdv.deliveryTraderSid = "";
      if (this.truckKbn == appConfig.CARRIERCOMPDIV.DELIVERY) {
        // 路線業者
        body.reqIdv.deliveryTraderSid = this.truck;
      }

      // 輸送会社区分
      body.reqIdv.carrierCompCd = this.truckKbn;

      // 個人宛の場合
      if (!this.individualButton) {
        //
        body.reqIdv.privateIdentityFlg = "1";
        //発注者情報
        body.reqIdv.shipFromPostCd = this.shipFromPostCd;
        body.reqIdv.shipFromName = this.shipFromName;
        body.reqIdv.shipFromPrefectures = "";
        body.reqIdv.shipFromMunicipalities = "";
        body.reqIdv.shipFromCharacterName = "";
        body.reqIdv.shipToAddressDetail = this.shipToCharacterName;
        body.reqIdv.shipFromTelephoneNumber = this.shipFromTelephoneNumber;
        //発注者情報
        body.reqIdv.deliveryTime = this.deliveryTime;
        body.reqIdv.purchasePrice = commonFunction.getCommaRemoval(this.purchasePrice);
        //届け先情報
        body.reqIdv.shipToName = this.shipToName;
        body.reqIdv.relatedPartyNameShort = this.shipToName.substr(0, 20);
        body.reqIdv.shipToPostCd = this.shipToPostCd;
        body.reqIdv.shipToPrefectures = "";
        body.reqIdv.shipToMunicipalities = "";
        body.reqIdv.shipToCharacterName = "";
        body.reqIdv.shipFromAddressDetail = this.shipFromCharacterName;
        body.reqIdv.shipToTelephoneNumber = this.shipToTelephoneNumber;
      }
      //企業宛の場合
      else {
        body.reqIdv.privateIdentityFlg = "0";
      }

      body.reqIdv.outScheduleDtl = bodyList;
      // console.debug("addShpSch() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_OUTSCHEDULES, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("addShpSch() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.outsideClickNotCloseFlg = true;
              // 画面遷移ボタン
              this.infoDialog.homePageFlag = true;
              this.infoDialog.shpSchListFlg = true;
              this.infoDialog.shpSchAddFlg = true;
              this.infoDialog.InvResTransitionFlg =
                sessionStorage.getItem(appConfig.BTN_AUTHORITY_ID.P_STK_INVEN_ALLOC_RESERVE) ==
                "true"
                  ? true
                  : false;
              this.infoDialog.firstPageFlag = false;

              resolve(response);
              // エラー時
            } else {
              reject(new Error(jsonData.resCom.resComMessage));
            }
          })
          .catch((ex) => {
            reject(ex);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      }).catch((ex) => {
        this.infoDialog.message = ex.message;
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
        this.infoDialog.homePageFlag = false;
        this.infoDialog.shpSchListFlg = false;
        this.infoDialog.shpSchAddFlg = false;
        this.infoDialog.InvResTransitionFlg = false;
        this.infoDialog.outsideClickNotCloseFlg = false;
      });
    },

    /**
     * 倉庫リストボックスセット処理
     */
    checkWarehouse(value) {
      return commonFunction.getListSetName(this.warehouseList, value.warehouse);
    },

    /**
     * ロケリストボックスセット処理
     */
    checkLocation(item) {
      // return this.inputList[index].locationHint;

      return item.locationHint;
    },

    /**
     * 倉庫取得
     */
    getWarehouse() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isMst = "0";
      config.params.searchCategory = "0";
      // config.params.clientSid = this.suppliersSelected;

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_WAREHOUSEBIZ_USESEARCH, config)
          .then((response) => {
            // console.debug("getWarehouse() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.warehouses.forEach((row) => {
                list.push({
                  text: row.warehouseLanguage[0].warehouseName,
                  value: row.warehouseLanguage[0].warehouseSid,
                  name: row.warehouseLanguage[0].warehouseName,
                });
              });
              this.warehouseList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.simpleDialog = true;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.simpleDialog = true;
            // OK NGボタン
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * ロケリストボックス変更時メソッド
     */
    changeLocation(value, item, index) {
      // 既存ロケを選択時
      let val = this.inputList[index].locationList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        item.locationHint = val.text;
        item.location = val.value;
        // item.locaSearch = val.text;
      }
      item.locationList = item.locationList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        item.locationSid = null;
        item.location = null;
        item.locationHint = null;
      } else {
        // 処理なし
      }
    },

    /** 輸送会社取得処理 */
    changeCarrier() {
      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();
      // 営業所SID（ログインユーザ情報）
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // 取引先SID
      config.params.clientSid = this.suppliersSelected;
      //出荷先SID
      config.params.toSid = this.shippingSelected;
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_DELIVERY_BIZUSE, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 成功
            if (jsonData.resCom.resComCode == "000") {
              jsonData.resIdv.deliveryTrader.forEach((row) => {
                if (row.warehouseOwnerDiv != "04") {
                  //路線会社以外の場合
                  this.truckNm = row.warehouseOwnerDivName;
                  // API検索用
                  this.truckKbn = row.warehouseOwnerDiv;
                  this.truck = "";
                } else {
                  //路線会社の場合
                  this.truckNm = row.deliveryTraderName;
                  // API検索用
                  this.truckKbn = row.warehouseOwnerDiv;
                  this.truck = row.deliveryTraderSid;
                }
              });
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          });
      });
    },
    /**
     * プルダウンチェンジイベント
     */
    changeArrival(value) {
      // 既存品番/品名を選択時
      let val = this.shippingList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintArrival = val.displayText;
        this.getSuppliersSelected = val.displayText;
      }
      this.shippingList = this.shippingList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintArrival = "";
      } else {
        // 処理なし
      }

      //輸送業者表示対応
      if (this.shippingSelected != "" && this.shippingSelected != null) {
        //輸送会社取得
        this.changeCarrier();
      } else {
        // 画面表示用
        this.truckNm = "";
        // API検索用
        this.truckKbn = "";
        this.truck = "";
      }

      //個人宅用処理
      if (this.shippingSelected != null) {
        for (var i = 0; i < this.shippingList.length; i++) {
          for (var b = 0; b < this.individualList.length; b++) {
            if (this.shippingList[i].value == this.shippingSelected) {
              // if (this.shippingList[i].baseCd == this.individualCode) {
              if (this.shippingList[i].baseCd == this.individualList[b].text) {
                //「個人宅」ボタン活性処理
                this.individualButton = false;
                this.dialogDeliveryAdress = true;
                //3行目表示
                this.deliverystyle = "display: block";
                break;
              } else {
                this.initializationdestination();
              }
            }
          }
        }
      } else {
        this.initializationdestination();
      }
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.checkDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.checkDialog.isOpen = true;
      this.checkDialog.screenFlag = true;
      this.checkDialog.changeFlag = false;
      this.checkDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 連続登録の場合
      if (this.continueAddFlg) {
        this.suppliersSelected = "";
        // 連続登録フラグ
        this.continueAddFlg = false;
      }
      // 変更検知用の設定
      this.shippingScheduleDateCal = "";
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
      // 個人宅の登録有りの場合
      if (this.individualAddFlg) {
        this.$refs.destinationList.resetValidation();
      }
    },
  },
  computed: {},
  watch: {
    shippingScheduleDateCal: {
      handler(val) {
        this.shippingScheduleDate = this.formatDate(val);
      },
      deep: true,
    },
    dateToDeliveryCal(val) {
      this.dateToDelivery = this.formatDate(val);
    },
    shippingScheduleDate: {
      handler() {
        // 出荷予定日のチェック
        if (dateTimeHelper.isDisplayDateFormat(this.shippingScheduleDate)) {
          // 出荷予定日が正しい日付の場合、取引先を取得する
          this.getCustomInfo(this.shippingScheduleDate);
          this.locInfo.referenceDate = dateTimeHelper.convertUTC(this.shippingScheduleDate);
        } else {
          // 上記以外の場合、処理なし
        }
      },
      deep: true,
    },
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    isPaid: function (newVal) {
      if (newVal == "02") {
        this.isDisabledFreeReason = false;
      } else {
        this.isDisabledFreeReason = true;
        this.freeReasonSelected = "";
      }
    },
    shippingStatusSelected: function (newVal) {
      if (newVal == "02") {
        this.isDisabledBlame = false;
        this.isRes = this.isResBadValue;
        this.isResBadCount++;
      } else {
        if (this.isResBadCount > 0) {
          this.isDisabledBlame = true;
          this.isResBadValue = this.isRes;
          this.isRes = "01";
        }
      }
    },
    inputList: function (inputL) {
      inputL.forEach((value) => {
        if (value.isWatchAdded === void 0) {
          this.$watch(
            () => value.search,
            (val) => {
              if (val ?? false) {
                // 取引先が選択されてない場合、処理しない
                if (this.suppliersSelected == null || this.suppliersSelected == "") {
                  return;
                }

                // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
                if (val == value.productHint) {
                  return;
                }

                // // クリア処理
                value.productCd = "";
                value.productHint = "";
                value.productNm = "";
                value.productHint = "";
                value.isNew = false;
                value.productQty = null;
                value.lot = "";
                value.lotFlg = "0";
                value.serial = "";
                value.serialFlg = "0";
                value.dueDate = "";
                value.dueDateFlg = "0";
                value.lotSubkey1 = "";
                value.lotSubkey1Flg = "0";
                value.lotSubkey2 = "";
                value.lotSubkey2Flg = "0";
                value.caseSu = null;
                value.ballSu = null;
                value.baraSu = null;
                value.locationSid = null;
                value.location = null;
                value.locationHint = null;
                value.warehouseSid = null;
                value.warehouse = null;

                // 入力桁数が定義した数以上の場合検索処理
                if (val.length >= appConfig.CNTCHARITEM) {
                  const itemsBiz = getParameter.getItemsBizAddName2(
                    this.suppliersSelected,
                    dateTimeHelper.convertUTC(this.shippingScheduleDate),
                    val,
                    "1"
                  );

                  Promise.all([itemsBiz])
                    .then((result) => {
                      // 画面の初期値を設定します。
                      value.productList = result[0];
                      // console.debug("inputList: function (inputL) Result[0]", result[0]);
                    })
                    .catch((ex) => {
                      this.infoDialog.message = ex;
                      this.infoDialog.title = appConfig.DIALOG.title;
                      this.infoDialog.isOpen = true;
                      this.infoDialog.firstPageFlag = true;
                    })
                    .finally(() => {
                      // ローディング画面表示OFF
                      this.loadingCounter = 0;
                    });
                } else {
                  value.productList.length = 0;
                  value.productList = [];
                }
              }
            }
          );
          this.$watch(
            () => value.locaSearch,
            (val) => {
              if (val ?? false) {
                // 取引先が選択されてない場合、処理しない
                if (this.suppliersSelected == null || this.suppliersSelected == "") {
                  return;
                }

                // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
                if (val == value.locationHint) {
                  return;
                }

                // 入力桁数が定義した数異常の場合検索処理
                if (val.length >= appConfig.CNTCHARITEM) {
                  this.loadingCounter = 1;

                  const locationBiz = getParameter.getLocationBizUse({
                    warehouseSid: value.warehouse,
                    locationNo: val,
                  });
                  Promise.all([locationBiz])
                    .then((result) => {
                      value.locationList = result[0];
                    })
                    .catch((ex) => {
                      this.infoDialog.message = ex;
                      this.infoDialog.title = appConfig.DIALOG.title;
                      this.infoDialog.isOpen = true;
                      this.infoDialog.firstPageFlag = true;
                    })
                    .finally(() => {
                      // ローディング画面表示OFF
                      this.loadingCounter = 0;
                    });
                } else {
                  value.locationList.length = 0;
                  value.locationList = [];
                }
              }
            }
          );
        }
        value.isWatchAdded = true;
      });
    },
    /**
     * 入荷元取得
     */
    getSuppliersSelected(val) {
      if (val ?? false) {
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          this.shippingList = [];
          this.shippingSelected = "";
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintArrival) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 選択した会社コードを取得
          let officeCd = this.supplierList.find(
            (element) => element.value === this.suppliersSelected
          ).clientSid;
          // From（出荷元）
          const fromList = getParameter.getRelatedBizPartialMatch(
            appConfig.ISGETCLIENT.FROMTO,
            dateTimeHelper.convertUTC(this.arrivalScheduleDate),
            undefined,
            officeCd,
            val
          );

          Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。
              this.shippingList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.shippingList.length = 0;
          this.shippingList = [];
        }
      }
    },
    /**
     * 取引先を変更する場合はクリアする
     */
    suppliersSelected() {
      // 出荷先、輸送会社名、明細行クリア
      this.shippingList = [];
      this.shippingSelected = "";
      this.truckNm = "";
      this.truckKbn = "";
      this.truck = "";
      if (this.inputList.length != 0) {
        this.inputList.splice(0, this.inputList.length);
        for (var j = 0; j < 10; j++) {
          this.defaultItem.No = j + 1;
          this.inputList.push(Object.assign({}, this.defaultItem));
        }
      }
      this.totalCount = 0;
      let sumList = [];
      sumList.push({
        No: "",
        productCd: i18n.tc("label.lbl_totalNum") + ":",
        productQty: this.totalCount,
        lot: "",
        caseSu: "",
        ballSu: "",
        baraSu: "",
        warehouse: "",
        location: "",
        accInfos: "",
        deleteData: "",
      });
      this.inputSumList = [];
      this.inputSumList = sumList;

      // 取引先あり
      if (this.suppliersSelected) {
        // ローディング画面表示ON
        this.loadingCounter = 1;
        // PC画面制御
        const functionPermissions = getParameter.getFunctionPermissions({
          conditions: [
            {
              clientSid: this.suppliersSelected,
              attachResourceKey: appConfig.MENU_ID.P_SHP_SCHEDULE_ADD,
              item01: "DeliveryDate",
            },
          ],
        });
        Promise.all([functionPermissions])
          .then((result) => {
            if (result[0].find((permission) => permission.item01 == "DeliveryDate")) {
              this.isDeliveryDateRequireFlg = true;
            } else {
              this.isDeliveryDateRequireFlg = false;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      } else {
        this.isDeliveryDateRequireFlg = false;
      }
    },
    /**
     * 輸送会社選択のクリア
     */
    truckNm() {
      // 輸送会社名をクリアする場合
      if (this.truckNm == null) {
        this.truckKbn = "";
        this.truck = "";
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
//画面優先順位

.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
//備考欄、テキストボックス
.search-textbox-remark {
  width: 44.7rem;
}
//輸送会社選択：テキストボックス
.serch-textbox-tr {
  width: 16rem;
}

#table-guide {
  padding: 0.2rem 0 0.1rem 0.2rem;
  display: inline-block;
  width: 13rem;
  text-align: left;
  border: solid 1px;
  margin: 0.2rem 0.5rem;
}
.item-spacer-sum {
  margin-left: 5.6rem;
  margin-right: 6px;
}

#listSumDataSpecial {
  ::-webkit-scrollbar-thumb {
    background: red;
  }

  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child {
      background-color: #eee;
      height: 0rem;
    }

    // グループヘッダー
    .v-row-group__header {
      background-color: #dde5f0;
      height: 0px;
    }

    // データがありません時のスタイル
    .v-data-table__empty-wrapper {
      background-color: #ffffff;
    }

    // テーブルデータ部分
    td {
      font-size: large;

      &:nth-child(1) {
        text-align: right;
        width: 4%;
      }
      &:nth-child(2) {
        text-align: right;
        width: 10%;
      }
      &:nth-child(3) {
        text-align: right;
        width: 11%;
      }

      &:nth-child(4) {
        text-align: right;
        width: 10%;
      }
      &:nth-child(5) {
        text-align: right;
        width: 9%;
      }
      &:nth-child(6) {
        text-align: right;
        width: 9%;
      }
      &:nth-child(7) {
        text-align: right;
        width: 9%;
      }
      &:nth-child(8) {
        text-align: right;
        width: 10%;
      }
      &:nth-child(9) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(10) {
        text-align: right;
        width: 4%;
      }

      &:nth-child(11) {
        text-align: right;
        width: 5%;
      }
    }
  }

  white-space: nowrap;
  pointer-events: none;
  font-weight: 700;
  // overflow-y: scroll;
  // overflow-y: hidden;
}

.tableData {
  border-collapse: collapse;
}

#listData ::v-deep th,
#listData ::v-deep td {
  padding: 0 12px;
}

.lbl-screen-name {
  padding: 1rem 0;
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  color: #000;
  margin-left: -15px;
}

.add-textbox-remark {
  width: 68rem;
}

#dialogDelivery .v-text-field.v-text-field--enclosed ::v-deep .v-text-field__details {
  margin-bottom: 0px;
}

#dialogDelivery ::v-deep .error--text {
  margin-bottom: 5px;
}
</style>
