<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_shipTruckNumAdd" />
      <v-form ref="searchForm" lazy-validation>
        <v-container fluid>
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="searchForm.suppliersSelected"
                :items="searchForm.supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                :rules="[rules.supplierRequired]"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require asterisk-spacer">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- カレンダー -->
            <!-- 出荷日-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="searchForm.shipScheduleDate"
                :label="$t('label.lbl_scheduleDate')"
                @change="changeDate('shipScheduleDateCal', $event)"
                :rules="[rules.inputRequired, rules.yyyymmdd]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="searchForm.shipScheduleDateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="searchForm.shipScheduleDateCal"
                  @input="searchForm.shipScheduleDateMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevDate('shipScheduleDateCal')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextDate('shipScheduleDateCal')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 納品日-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="searchForm.deliveryDate"
                :label="$t('label.lbl_delDate')"
                @change="changeDate('deliveryDateCal', $event)"
                :rules="[
                  rules.yyyymmdd_create,
                  rules.isFutureDate(
                    searchForm.deliveryDate,
                    searchForm.shipScheduleDate,
                    $t('label.lbl_scheduleDate')
                  ),
                ]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
              >
              </v-text-field>
            </div>
            <div class="calender-btn-area">
              <v-menu
                v-model="searchForm.deliveryDateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="searchForm.deliveryDateCal"
                  @input="searchForm.deliveryDateMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevDate('deliveryDateCal')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextDate('deliveryDateCal')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- カレンダー終わり -->

            <!-- 都道府県-->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="searchForm.prefecture"
                :items="searchForm.prefectureList"
                :label="$t('label.lbl_prefectures')"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 輸送会社名 -->
            <div class="search-textbox">
              <v-text-field
                outlined
                dense
                v-model="searchForm.truckNm"
                :label="$t('label.lbl_shippingCompanyName')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                readonly
                clearable
                @click:append="append(), (searchForm.truckNm = '')"
              />
            </div>
            <!-- 輸送会社選択ボタン -->
            <div style="float: left">
              <v-btn color="primary" class="api-btn" v-on:click="openTruck">
                {{ $t("btn.btn_addShpAdress") }}
              </v-btn>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
          </v-row>
        </v-container>
      </v-form>

      <v-container fluid>
        <v-row>
          <!--ボタン領域-->
          <div class="search-row-exeBtn">
            <!--検索ボタン領域-->
            <div class="btn-search-area" style="float: left">
              <!-- 検索ボタン -->
              <v-btn color="primary" class="api-btn" @click="search()">
                {{ $t("btn.btn_search") }}
              </v-btn>
              <!--画面クリアボタン-->
              <v-btn class="other-btn" @click="refresh()">{{ $t("btn.btn_clear") }}</v-btn>
            </div>
            <div class="btn-area">
              <!-- 登録ボタン -->
              <v-btn class="post-btn" @click="insertDialog()" :disabled="inputList.length == 0">
                {{ $t("btn.btn_insert") }}
              </v-btn>
              <!-- メッセージダイアログ-->
              <ConfirmDialog
                :isShow.sync="ConfirmDialog.isOpen"
                :message="ConfirmDialog.message"
                :okAction="insert"
                :screenFlag="ConfirmDialog.screenFlag"
                :redMessage="ConfirmDialog.redMessage"
                :changeFlag="ConfirmDialog.changeFlag"
              />
            </div>
          </div>
        </v-row>
      </v-container>

      <v-form ref="batchForm" lazy-validation>
        <v-container fluid>
          <v-row class="search-row" style="justify-content: flex-end; margin-right: 0px">
            <!-- 一括クリアボタン -->
            <v-btn class="other-btn" @click="batchClear()" :disabled="inputList.length == 0">
              {{ $t("btn.btn_batchClear") }}
            </v-btn>
            <!-- 輸送会社名 -->
            <div class="first-search-item date-style">
              <v-text-field
                v-model.trim="batchForm.truckNm"
                :label="$t('label.lbl_shippingCompanyName')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                outlined
                dense
                clearable
                readonly
                v-on:click="batchOpenTruck"
              />
            </div>
            <!-- 号車 -->
            <div class="first-search-item date-style">
              <v-text-field
                v-model.trim="batchForm.transportVehicleCd"
                :label="$t('label.lbl_shipTruckNum')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                outlined
                dense
                clearable
                :rules="[rules.isStrLen(batchForm.transportVehicleCd, 20)]"
              />
            </div>
            <!-- 順番 -->
            <div class="first-search-item date-style">
              <v-text-field
                outlined
                dense
                v-model.trim="batchForm.autoNo"
                :label="$t('label.lbl_order')"
                class="input-number"
                clear-icon="mdi-close-circle"
                clearable
                maxlength="3"
                style="margin: 0px"
                :rules="[rules.isNaturalNum]"
              />
            </div>
            <!-- 一括ボタン -->
            <v-btn class="other-btn" @click="batchInfluence()" :disabled="inputList.length == 0">
              {{ $t("btn.btn_batch") }}
            </v-btn>
          </v-row>
          <!--検索結果件数表示-->
          <div class="text-label" style="left: 0">
            <span class="toatlNum">
              {{ $t("label.lbl_total") + "：" + totalCount + $t("label.lbl_number") }}
            </span>
          </div>
          <v-row class="search-row" style="justify-content: flex-end; margin-right: 0px">
            <!--表示件数切替ボタン-->
            <div style="float: left">
              <v-btn-toggle
                v-model="itemsPerPageBtn"
                color="blue darken-2"
                class="toggle black--text"
                right
              >
                <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                  {{ 10 }}
                </v-btn>
                <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                  {{ 50 }}
                </v-btn>
                <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                  {{ 100 }}
                </v-btn>
              </v-btn-toggle>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <v-form ref="registcheck" lazy-validation>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :ripple="false"
          :hidden="true"
          :items-per-page="inputList.length"
        >
          <!-- 輸送会社名リストボックス -->
          <template v-slot:[`item.carrierCompCd`]="{ item, index }">
            <v-autocomplete
              class="truck-autocomplete"
              v-model="item.carrierCompCd"
              :items="carrierCompList"
              persistent-hint
              dense
              width="170px"
              :ref="'cursorProcd' + index"
              :rules="[rules.inputRequired]"
            >
              <option disabled value="item.carrierCompCd"></option>
              <template slot="item" slot-scope="data">
                <span class="auto-txt-size"> {{ data.item.text }} </span></template
              >
            </v-autocomplete>
          </template>

          <!-- 路線会社名リストボックス -->
          <template v-slot:[`item.deliveryTraderSid`]="{ item, index }">
            <v-autocomplete
              class="truck-autocomplete"
              v-model="item.deliveryTraderSid"
              :items="deliveryTraderList"
              persistent-hint
              dense
              width="170px"
              :ref="'cursorProcd' + index"
              :disabled="item.carrierCompCd != '04'"
              :error-messages="item.deliveryTraderCheckMsg"
              :rules="[rules.deliveryTraderRequired(item.carrierCompCd, item.deliveryTraderSid)]"
            >
              >
              <option disabled value="item.deliveryTraderSid"></option>
              <template slot="item" slot-scope="data">
                <span class="auto-txt-size"> {{ data.item.text }} </span>
              </template>
            </v-autocomplete>
          </template>
          <!-- 出荷先別選択 -->
          <template v-slot:[`item.checkByShipFlg`]="{ item }">
            <input
              type="checkbox"
              v-model="item.checkByShipFlg"
              @change="checkByShip(item)"
              style="transform: scale(2)"
            />
          </template>

          <!-- 選択 -->
          <template v-slot:[`item.checkFlg`]="{ item }">
            <input type="checkbox" v-model="item.checkFlg" style="transform: scale(2)" />
          </template>

          <!-- 号車 -->
          <template v-slot:[`item.transportVehicleCd`]="{ item }">
            <div id="table-lot">
              <v-text-field
                v-model.trim="item.transportVehicleCd"
                class="text-box"
                clear-icon="mdi-close-circle"
                outlined
                dense
                clearable
                :rules="[
                  rules.isCorrelationRequired(
                    item.transportVehicleCd,
                    item.autoNo,
                    $t('label.lbl_shipTruckNum')
                  ),
                  rules.isStrLen(item.transportVehicleCd, 20),
                ]"
              />
            </div>
          </template>

          <!-- 順番 -->
          <template v-slot:[`item.autoNo`]="{ item }">
            <v-text-field
              outlined
              dense
              v-model.trim="item.autoNo"
              class="input-number"
              persistent-hint
              maxlength="4"
              :rules="[
                rules.isCorrelationRequired(
                  item.autoNo,
                  item.transportVehicleCd,
                  $t('label.lbl_order')
                ),
                rules.isNaturalNum,
              ]"
              clear-icon="mdi-close-circle"
              clearable
            />
          </template>
        </v-data-table>
      </v-form>

      <!-- データテーブル -->
      <v-form ref="resultForm" lazy-validation>
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            :ripple="false"
            disable-filtering
            disable-sort
            :page.sync="page"
            :items-per-page="itemsPerPage"
            :hide-default-footer="true"
            height="530px"
          >
            <!-- 輸送会社名リストボックス -->
            <template v-slot:[`item.carrierCompCd`]="{ item, index }">
              <v-autocomplete
                class="truck-autocomplete"
                v-model="item.carrierCompCd"
                :items="carrierCompList"
                persistent-hint
                dense
                width="170px"
                :ref="'cursorProcd' + index"
                :rules="[rules.inputRequired]"
              >
                <option disabled value="item.carrierCompCd"></option>
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </template>

            <!-- 路線会社名リストボックス -->
            <template v-slot:[`item.deliveryTraderSid`]="{ item, index }">
              <v-autocomplete
                class="truck-autocomplete"
                v-model="item.deliveryTraderSid"
                :items="deliveryTraderList"
                persistent-hint
                dense
                width="170px"
                :ref="'cursorProcd' + index"
                :disabled="item.carrierCompCd != '04'"
                :error-messages="item.deliveryTraderCheckMsg"
                :rules="[rules.deliveryTraderRequired(item.carrierCompCd, item.deliveryTraderSid)]"
              >
                >
                <option disabled value="item.deliveryTraderSid"></option>
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </template>
            <!-- 出荷先別選択 -->
            <template v-slot:[`item.checkByShipFlg`]="{ item }">
              <input
                type="checkbox"
                v-model="item.checkByShipFlg"
                @change="checkByShip(item)"
                style="transform: scale(2)"
              />
            </template>

            <!-- 選択 -->
            <template v-slot:[`item.checkFlg`]="{ item }">
              <input type="checkbox" v-model="item.checkFlg" style="transform: scale(2)" />
            </template>

            <!-- 号車 -->
            <template v-slot:[`item.transportVehicleCd`]="{ item }">
              <div id="table-lot">
                <v-text-field
                  v-model.trim="item.transportVehicleCd"
                  class="text-box"
                  clear-icon="mdi-close-circle"
                  outlined
                  dense
                  clearable
                  :rules="[
                    rules.isCorrelationRequired(
                      item.transportVehicleCd,
                      item.autoNo,
                      $t('label.lbl_shipTruckNum')
                    ),
                    rules.isStrLen(item.transportVehicleCd, 20),
                  ]"
                  @change="validatechek()"
                />
              </div>
            </template>

            <!-- 順番 -->
            <template v-slot:[`item.autoNo`]="{ item }">
              <v-text-field
                outlined
                dense
                v-model.trim="item.autoNo"
                class="input-number"
                persistent-hint
                maxlength="4"
                :rules="[
                  rules.isCorrelationRequired(
                    item.autoNo,
                    item.transportVehicleCd,
                    $t('label.lbl_order')
                  ),
                  rules.isNaturalNum,
                ]"
                clear-icon="mdi-close-circle"
                clearable
                @change="validatechek()"
              />
            </template>
          </v-data-table>
        </v-container>
      </v-form>
      <!-- ページネーション -->
      <div class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="Math.ceil(inputList.length / itemsPerPage)"
          :total-visible="7"
        ></v-pagination>
      </div>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :homePageFlag="infoDialog.homePageFlag"
        :shipTruckNumAddPageFlag="infoDialog.shipTruckNumAddPageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />
      <CheckDialog
        :isShow.sync="checkDialog.isOpen"
        :message="checkDialog.message"
        :screenFlag="checkDialog.screenFlag"
        :okAction="checkDialog.okAction"
        :redMessage="checkDialog.redMessage"
        :changeFlag="checkDialog.changeFlag"
      />

      <!-- トラック検索モーダル -->
      <v-dialog v-model="searchForm.isOpenTruck" :max-width="800">
        <ShipTruck
          :isOpenDetailDgialog.sync="searchForm.isOpenTruck"
          ref="truck"
          @click="saveTruck"
          :truckInfo="searchForm.truckInfo"
        />
      </v-dialog>
      <!-- トラック検索モーダル(一括登録) -->
      <v-dialog v-model="batchForm.isOpenTruck" :max-width="800">
        <Truck
          :isOpenDetailDgialog.sync="batchForm.isOpenTruck"
          ref="truck"
          @click="batchSaveTruck"
          :truckInfo="batchForm.truckInfo"
        />
      </v-dialog>
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> {{ $t("btn.btn_ok_en") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import ShipTruck from "../common/ShipTruck";
import Truck from "../common/Truck";
import Loading from "@/components/loading";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import SimpleDialog from "@/components/SimpleDialog";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import ConfirmDialog from "@/components/ConfirmDialog";
import CheckDialog from "@/components/ConfirmDialog";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.SCREEN_ID.P_SHP_011,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    CheckDialog,
    sideMenu,
    ShipTruck,
    Truck,
    NavBar,
  },
  mixins: [commonMixin, commonRules],
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    // メニュー
    openMenu: null,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    checkDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    //データ総件数
    totalCount: 0,
    // 検索
    searchForm: {
      // 取引先
      suppliersSelected: "",
      // 取引先プルダウン
      supplierList: [],
      // 出荷日
      shipScheduleDate: "",
      shipScheduleDateCal: "",
      shipScheduleDateMenu: false,
      // 納品日
      deliveryDate: "",
      deliveryDateCal: "",
      deliveryDateMenu: false,
      // 都道府県
      prefecture: "",
      prefectureList: [],
      // 輸送会社名（画面表示用）
      truckNm: "",
      // 輸送会社区分（登録用）
      truckKbn: "",
      // 輸送会社名（登録用）
      truck: "",
      // 輸送会社データ項目
      truckInfo: {
        suppliersSelected: "",
        truckInfo: "",
      },
      // 輸送会社ダイアログ
      isOpenTruck: false,
    },
    u_10: false,
    // ページング
    page: 1,
    getNumber: 1,
    itemsPerPageBtn: "10",
    itemsPerPage: 10,
    // 一括処理項目
    batchForm: {
      // 号車
      transportVehicleCd: "",
      // 順番
      autoNo: "",
      // 輸送会社名（画面表示用）
      truckNm: "",
      // 輸送会社区分（登録用）
      truckKbn: "",
      // 輸送会社名（登録用）
      truck: "",
      // 輸送会社データ項目
      truckInfo: {
        suppliersSelected: "",
        // 路線業者SID
        deliveryTraderSid: "",
        // 輸送業者コード
        carrierCompCd: "",
      },
      // 輸送会社ダイアログ
      isOpenTruck: false,
    },
    // 路線会社プルダウン
    deliveryTraderList: [],
    // 輸送会社プルダウン
    carrierCompList: [],
    deliveryTraderCheckMsg: "",
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 一覧
    inputList: [],
    // ヘッダ
    headerItems: [
      // 納品日
      {
        text: i18n.tc("label.lbl_delDate"),
        value: "deliveryDate",
        width: "100px",
        align: "center",
        sortable: false,
      },
      // 出荷先
      {
        text: i18n.tc("label.lbl_shippingTo"),
        value: "toName",
        width: "100px",
        align: "center",
        sortable: false,
      },
      // 出荷No
      {
        text: i18n.tc("label.lbl_shippingNo"),
        value: "outListNo",
        width: "100px",
        align: "center",
        sortable: false,
      },
      // 住所
      {
        text: i18n.tc("label.lbl_popAddress"),
        value: "prefectures",
        width: "100px",
        align: "center",
        sortable: false,
      },
      // 数量
      {
        text: i18n.tc("label.lbl_quantity"),
        value: "shipScheduleQuantity",
        width: "100px",
        align: "center",
        sortable: false,
      },
      // 重量(kg)
      {
        text: i18n.tc("label.lbl_weight"),
        value: "capacityWeight",
        width: "100px",
        align: "center",
        sortable: false,
      },
      // 出荷先別累積
      {
        text: i18n.tc("label.lbl_cumulationByShip"),
        value: "cumulativeSum",
        width: "100px",
        align: "center",
        sortable: false,
      },
      // 輸送会社
      {
        text: i18n.tc("label.lbl_trackName"),
        value: "carrierCompCd",
        width: "100px",
        align: "center",
        sortable: false,
        class: "asta",
        isRequired: true,
      },
      // 路線会社
      {
        text: i18n.tc("label.lbl_routeslipcompany"),
        value: "deliveryTraderSid",
        width: "100px",
        align: "center",
        sortable: false,
      },
      // 出荷先別選択
      {
        text: i18n.tc("label.lbl_checkByShip"),
        value: "checkByShipFlg",
        width: "100px",
        align: "center",
        sortable: false,
      },
      // 選択
      {
        text: i18n.tc("label.lbl_choice"),
        value: "checkFlg",
        width: "100px",
        align: "center",
        sortable: false,
      },
      // 号車
      {
        text: i18n.tc("label.lbl_shipTruckNum"),
        value: "transportVehicleCd",
        width: "100px",
        align: "center",
        sortable: false,
      },
      // 順番
      {
        text: i18n.tc("label.lbl_order"),
        value: "autoNo",
        width: "100px",
        align: "center",
        sortable: false,
      },
    ],
    rules: {
      deliveryTraderRequired: (carrierCompCd, deliveryTraderSid) => {
        if (carrierCompCd == "04" && (deliveryTraderSid == null || deliveryTraderSid == "")) {
          return i18n.tc("check.chk_input");
        } else {
          return true;
        }
      },
    },
  }),
  created() {},
  methods: {
    append() {
      this.searchForm.truckKbn = "";
    },

    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },
    validatechek() {
      this.$refs.resultForm.validate();
    },

    /**
     * リフレッシュ
     */
    refresh() {
      this.checkDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.checkDialog.isOpen = true;
      this.checkDialog.screenFlag = true;
      this.checkDialog.changeFlag = false;
      this.checkDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 取引先
      this.searchForm.suppliersSelected = "";
      // 出荷日
      this.searchForm.shipScheduleDate = "";
      this.searchForm.shipScheduleDateMen = false;
      // 納品日
      this.searchForm.deliveryDate = "";
      this.searchForm.deliveryDateMenu = false;
      // 都道府県
      this.searchForm.prefecture = "";
      // 輸送会社名（画面表示用）
      this.searchForm.truckNm = "";
      // 輸送会社名（登録用）
      this.searchForm.truck = "";
      // 号車
      this.batchForm.transportVehicleCd = "";
      // 順番
      this.batchForm.autoNo = "";
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.searchForm.resetValidation();
    },
    // ページング
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
    },
    /**
     * 都道府県取得
     */
    getPrefecture() {
      const config = this.$httpClient.createGetApiRequestConfig();
      // 営業所
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // console.debug("GetParameter getPrefecture Url", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_PREFECTURE, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("GetParameter getPrefecture Response", response, jsonData);
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.prefecture.forEach((row) => {
                list.push({
                  value: row.prefecturesCd,
                  text: row.prefectures,
                  name: row.prefectures,
                });
              });
              resolve(list);
            } else {
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((ex) => {
            console.error("GetParameter getPrefecture Err", ex);
            reject(messsageUtil.getMessage("P-999-999_999_E"));
          });
      });
    },
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 理由コード
      const reasonList = getParameter.getCodeMst(appConfig.CODETYPE.OUT_CARRIER_COMP_DIV);
      Promise.all([reasonList])
        .then((result) => {
          // 画面の初期値を設定します。
          //console.debug("carrierCompList", result);
          const list = [];
          result[0].forEach((row) => {
            list.push({
              text: row.text,
              name: row.name,
              value: row.value,
            });
          });
          this.carrierCompList = list;
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // 路線業者マスタAPI（路線会社リストボックス）
    getDeliveryTrader() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.clientSid = this.searchForm.suppliersSelected;
      config.params.isMst = "0";
      config.params.searchCategory = "0";
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_DELIVERY, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            //console.debug("getDeliveryTrader() Response", response);
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [{ text: "", name: "", value: "" }];
              jsonData.resIdv.deliveryTrader.forEach((row) => {
                list.push({
                  text: row.deliveryTraderLanguage[0].deliveryTraderName,
                  name: row.deliveryTraderLanguage[0].deliveryTraderName,
                  value: row.deliveryTraderLanguage[0].deliveryTraderSid,
                });
              });
              this.deliveryTraderList = list;
              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 初期データ取得
     */
    getCustomInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC()
      );
      // 都道府県
      const prefectureList = this.getPrefecture();

      Promise.all([clientList, prefectureList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.searchForm.supplierList = result[0];
          this.searchForm.prefectureList = result[1];
          // 取引先が1件の場合、取引先を自動選択する
          this.searchForm.supplierList.length == 1
            ? (this.searchForm.suppliersSelected = this.searchForm.supplierList[0].value)
            : "";
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 号車登録画面： 検索ボタン押下処理
     */
    search() {
      // バリデーション
      if (this.$refs.searchForm.validate()) {
        // ローディング画面表示ON
        this.loadingCounter = 1;
        this.getDeliveryTrader();
        // httpClientで使用する共通IFパラメータ等を作成する。
        const config = this.$httpClient.createGetApiRequestConfig();
        // 営業所SID（ログインユーザ情報）
        config.params.officeSid = sessionStorage.getItem("sales_office_sid");
        // 取引先SID
        config.params.clientSid = this.searchForm.suppliersSelected;
        // 出荷日
        config.params.outScheduleDate = dateTimeHelper.convertUTC(this.searchForm.shipScheduleDate);
        // 納品日
        if (this.searchForm.deliveryDate) {
          config.params.deliveryDate = this.searchForm.deliveryDate;
        }
        // 都道府県
        if (this.searchForm.prefecture) {
          config.params.prefecturesCd = this.searchForm.prefecture;
        }
        // 路線業者SID（輸送会社名)
        if (this.searchForm.truckKbn) {
          config.params.carrierCompCd = this.searchForm.truckKbn;
          if (this.searchForm.truck) {
            config.params.deliveryTraderSid = this.searchForm.truck;
          }
        }

        // console.debug("search() Config", config);
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.BIZ_TRANSPORT_VEHICLE, config)
            .then((response) => {
              //console.debug("search() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // 正常時
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                this.totalCount = jsonData.resCom.resComCount;
                const list = [];
                // 号車情報リストを取得
                jsonData.resIdv.transportVehicle.forEach((row) => {
                  list.push({
                    // 出荷SID
                    outListSid: row.outListSid,
                    // 納品日
                    deliveryDate: row.deliveryDate
                      ? dateTimeHelper.convertUTC2JST(row.deliveryDate).substr(0, 10)
                      : "",
                    // 出荷先
                    toName: row.toName,
                    // 出荷No
                    outListNo: row.outListNo,
                    // 住所(都道府県)
                    prefectures: row.prefectures,
                    // 数量
                    shipScheduleQuantity: row.shipScheduleQuantity,
                    // 重量
                    capacityWeight: row.capacityWeight,
                    // 出荷先別累積
                    cumulativeSum: row.cumulativeSum,
                    // 輸送会社
                    carrierCompName: row.carrierCompName,
                    // 輸送会社CD
                    carrierCompCd: row.carrierCompCd,
                    // 路線会社
                    deliveryTraderName: row.deliveryTraderName,
                    // 路線会社SID
                    deliveryTraderSid: row.deliveryTraderSid,
                    // 号車
                    transportVehicleCd: row.transportVehicleCd,
                    // 順番
                    autoNo: row.autoNo,
                    // 号車情報の更新日時
                    updateDatetime: row.updateDatetime,
                    // 出荷予定伝票の更新日時
                    outScheduleUpdateDatetime: row.outScheduleUpdateDatetime,
                    // 出荷先別選択
                    checkByShipFlg: false,
                    // 選択フラグ
                    checkFlg: false,
                    // グループ番号
                    groupNumber: row.groupNumber,
                  });
                });
                this.inputList = list;
                resolve(response);
              } else {
                // エラー時
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },

    /**
     * 号車登録画面： 一括クリアボタン押下処理
     */
    batchClear() {
      // 選択数
      const chkCnt = this.inputList.filter((item) => item.checkFlg == "1").length;
      if (chkCnt == 0) {
        // 警告ダイアログ表示
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
      } else {
        // 一括クリア
        this.inputList.forEach((row) => {
          if (row.checkFlg) {
            row.transportVehicleCd = null;
            row.autoNo = null;
            row.carrierCompCd = "";
            row.deliveryTraderSid = "";
          }
        });
        // チェックボックスクリア
        this.inputList.forEach((row) => {
          row.checkFlg = false;
          row.checkByShipFlg = false;
        });
      }
    },

    /**
     * 号車登録画面： 一括ボタン押下処理
     */
    batchInfluence() {
      // バリデーション
      if (this.$refs.batchForm.validate()) {
        // 選択数
        const chkCnt = this.inputList.filter((item) => item.checkFlg == "1").length;
        if (chkCnt == 0) {
          // 警告ダイアログ表示
          this.updateDialogMessage = true;
          this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
        } else {
          // 一括反映
          this.inputList.forEach((row) => {
            if (row.checkFlg) {
              if (this.batchForm.transportVehicleCd) {
                row.transportVehicleCd = this.batchForm.transportVehicleCd;
              }
              if (this.batchForm.autoNo) {
                row.autoNo = this.batchForm.autoNo;
              }
              if (this.batchForm.truckNm) {
                if (this.batchForm.truckInfo.carrierCompCd) {
                  row.carrierCompCd = this.batchForm.truckInfo.carrierCompCd;
                }
                if (this.batchForm.truckInfo.deliveryTraderSid) {
                  row.deliveryTraderSid = this.batchForm.truckInfo.deliveryTraderSid;
                }
              }
            }
          });
          // 一括の号車、順番をクリア
          this.batchForm.transportVehicleCd = null;
          this.batchForm.autoNo = null;
          this.batchForm.truckInfo.carrierCompCd = null;
          this.batchForm.truckInfo.deliveryTraderSid = null;
          this.batchForm.truckNm = null;
          // チェックボックスクリア
          this.inputList.forEach((row) => {
            row.checkFlg = false;
            row.checkByShipFlg = false;
          });
        }
      }
    },

    /**
     * 号車登録画面： 登録ボタン押下処理
     */
    insertDialog() {
      // バリデーション
      this.$refs.resultForm.validate();
      if (this.$refs.registcheck.validate()) {
        this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
        this.ConfirmDialog.isOpen = true;
        this.ConfirmDialog.screenFlag = true;
        this.ConfirmDialog.changeFlag = false;
      }
    },

    /**
     * 登録処理
     */
    insert() {
      // バリデーション
      if (this.$refs.registcheck.validate()) {
        // ローディング画面表示ON
        this.loadingCounter = 1;

        // httpClientで使用する共通IFパラメータ等を作成する。
        const body = this.$httpClient.createRequestBodyConfig();
        // 共通IF項目 画面ID
        body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SHP_011;

        body.reqIdv.transportVehicle = this.inputList.map((row) => {
          // ブランクの場合にNULLで返す
          let autoNoValue;
          let transportVehicleCdValue;
          if (row.autoNo != null) {
            autoNoValue = row.autoNo.trim() === "" ? null : row.autoNo;
          }
          if (row.transportVehicleCd != null) {
            transportVehicleCdValue =
              row.transportVehicleCd.trim() === "" ? null : row.transportVehicleCd;
          }
          return {
            //出荷伝票SID
            outListSid: row.outListSid,
            // 号車
            transportVehicleCd: transportVehicleCdValue,
            // 順番
            autoNo: autoNoValue,
            // 輸送会社CD
            carrierCompCd: row.carrierCompCd,
            // 路線会社SID
            deliveryTraderSid: row.deliveryTraderSid,
            // 号車情報の更新日時
            updateDatetime: row.updateDatetime,
            // 出荷予定伝票の更新日時
            outScheduleUpdateDatetime: row.outScheduleUpdateDatetime,
          };
        });
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.BIZ_TRANSPORT_VEHICLE, body, appConfig.APP_CONFIG)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // console.debug("insert() Response", response);
              // 成功
              if (jsonData.resCom.resComCode == "000") {
                this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
                this.infoDialog.title = "結果";
                this.infoDialog.isOpen = true;
                this.infoDialog.homePageFlag = true;
                this.infoDialog.shipTruckNumAddPageFlag = true;
                this.infoDialog.outsideClickNotCloseFlg = true;
                resolve(response);
              } else {
                // エラー時
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    updateOk() {
      this.updateDialogMessage = false;
    },

    /**
     * 初期値
     */
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      // 出荷日
      this.searchForm.shipScheduleDate = dateTimeHelper.convertJST().substr(0, 10);
      this.searchForm.shipScheduleDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.getCustomInfo();
      this.itemsPerPage = 10;
      this.itemsPerPageBtn = "10";
      this.getMstCode();
    },

    /**
     * 取引先リストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.searchForm.supplierList.length; i++) {
        if (this.searchForm.supplierList[i].value == this.searchForm.suppliersSelected) {
          return this.searchForm.supplierList[i].name;
        }
      }
    },

    /**
     * 都道府県リストボックス取得処理
     */
    setSchPrefecture() {
      for (var i = 0; i < this.searchForm.prefectureList.length; i++) {
        if (this.searchForm.prefectureList[i].value == this.searchForm.prefecture) {
          return this.searchForm.prefectureList[i].name;
        }
      }
    },

    /**
     * 出荷先別選択押下処理
     */
    checkByShip(item) {
      const chkFlg = item.checkByShipFlg;
      this.inputList.forEach((row) => {
        if (item.groupNumber == row.groupNumber) {
          row.checkByShipFlg = chkFlg;
          row.checkFlg = chkFlg;
        }
      });
    },

    /**
     * 配送先選択画面：配送先選択ボタン処理
     */
    openTruck() {
      if (this.searchForm.suppliersSelected) {
        // 取引先SID
        this.searchForm.truckInfo.suppliersSelected = this.searchForm.suppliersSelected;
        // 路線業者SID
        this.searchForm.truckInfo.deliveryTraderSid = this.searchForm.truck;
        // 輸送業者コード
        this.searchForm.truckInfo.carrierCompCd = this.searchForm.truckKbn;
        this.searchForm.isOpenTruck = true;
        if (this.$refs.truck != undefined) {
          // 表示時処理
          this.$refs.truck.init();
        }
      } else {
        this.searchForm.suppliersSelected = null;
      }
    },
    /**
     * 配送先選択画面：配送先選択ボタン処理
     */
    batchOpenTruck() {
      if (this.searchForm.suppliersSelected) {
        // 取引先SID
        this.batchForm.truckInfo.suppliersSelected = this.searchForm.suppliersSelected;
        // 路線業者SID
        this.batchForm.truckInfo.deliveryTraderSid = this.batchForm.truck;
        // 輸送業者コード
        this.batchForm.truckInfo.carrierCompCd = this.batchForm.truckKbn;
        this.batchForm.isOpenTruck = true;
        if (this.$refs.truck != undefined) {
          // 表示時処理
          this.$refs.truck.init();
        }
      } else {
        this.searchForm.suppliersSelected = null;
      }
    },
    /**
     * 配送先選択画面：登録後処理
     */
    saveTruck(value) {
      this.searchForm.truckInfo = value;
      // 画面表示用
      if (
        !this.searchForm.truckInfo.carrierCompName &&
        this.searchForm.truckInfo.carrierCompCd == "04"
      ) {
        this.searchForm.truckNm = i18n.tc("label.lbl_route");
      } else {
        this.searchForm.truckNm = this.searchForm.truckInfo.carrierCompName;
      }
      // API検索用
      this.searchForm.truckKbn = this.searchForm.truckInfo.carrierCompCd;
      this.searchForm.truck = this.searchForm.truckInfo.deliveryTraderSid;
      this.searchForm.isOpenTruck = false;
    },
    batchSaveTruck(value) {
      this.batchForm.truckInfo = value;
      // 画面表示用
      if (
        !this.batchForm.truckInfo.carrierCompName &&
        this.batchForm.truckInfo.carrierCompCd == "04"
      ) {
        this.batchForm.truckNm = i18n.tc("label.lbl_route");
      } else {
        this.batchForm.truckNm = this.batchForm.truckInfo.carrierCompName;
      }
      this.batchForm.isOpenTruck = false;
      this.batchForm.truckKbn = this.batchForm.truckInfo.carrierCompCd;
      this.batchForm.truck = this.batchForm.truckInfo.deliveryTraderSid;
    },
    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 日付入力
     */
    changeDate(modelName, val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.searchForm[modelName] = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.searchForm[modelName] = null;
      }
    },

    /**
     * 日付を－１日
     */
    prevDate(modelName) {
      if (this.searchForm[modelName] == null || this.searchForm[modelName] == "") {
        return;
      }
      let date = new Date(this.searchForm[modelName]);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.searchForm[modelName] != toDate) {
        this.searchForm[modelName] = toDate;
      } else {
        this.searchForm[modelName] = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },

    /**
     * 日付を＋１日
     */
    nextDate(modelName) {
      if (this.searchForm[modelName] == null || this.searchForm[modelName] == "") {
        return;
      }
      let date = new Date(this.searchForm[modelName]);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.searchForm[modelName] != toDate) {
        this.searchForm[modelName] = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.searchForm[modelName] = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    // 取引先
    "searchForm.suppliersSelected": function () {
      // 輸送会社名クリア
      this.searchForm.truckNm = "";
      this.searchForm.truckKbn = "";
      this.searchForm.truck = "";
      this.batchForm.truckInfo.carrierCompCd = null;
      this.batchForm.truckInfo.deliveryTraderSid = null;
      this.batchForm.truckNm = null;
    },
    // 出荷日
    "searchForm.shipScheduleDateCal": function (val) {
      this.searchForm.shipScheduleDate = this.formatDate(val);
    },
    // 納品日
    "searchForm.deliveryDateCal": function (val) {
      this.searchForm.deliveryDate = this.formatDate(val);
    },
    /**
     * 輸送会社選択のクリア
     */
    "searchForm.truckNm": function () {
      // 輸送会社名をクリアする場合
      if (this.searchForm.truckNm == null) {
        this.searchForm.truckKbn = "";
        this.searchForm.truck = "";
      }
    },
    /**
     * 輸送会社選択（一括入力）のクリア
     */
    "batchForm.truckNm": function () {
      // 輸送会社名をクリアする場合
      if (this.batchForm.truckNm == null) {
        this.batchForm.truckKbn = "";
        this.batchForm.truck = "";
      }
    },
    inputList: {
      handler(newValue) {
        // inputList の変更を監視
        for (const item of newValue) {
          // 輸送会社名リストボックスの選択が変更されたとき
          this.$watch(
            () => item.carrierCompCd,
            (newVal) => {
              if (newVal !== "04") {
                // 輸送会社名が変更されたら、路線会社名リストボックスの選択をクリア
                item.deliveryTraderSid = "";
              }
            }
          );
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
</style>
